import {
	createAsyncThunk,
	createEntityAdapter,
	createSlice,
} from '@reduxjs/toolkit'
import axios from 'axios'
import jwtServiceConfig from 'src/app/auth/services/jwtService/jwtServiceConfig'

const api_domain = jwtServiceConfig.apiUrl

export const getFilteredSystemFinancial = createAsyncThunk(
	'financeApp/systemFinancials/getFilteredSystemFinancial',
	async body => {
		const response = await axios.get(
			`${api_domain}meganet/finance/statistics/${body.systemType}/${body.game}/${body.startDate}/${body.endDate}`
		)
		const data = response.data.system_financial
		return data === undefined ? null : data
	}
)

export const getSystemFinancials = createAsyncThunk(
	'financeApp/systemFinancials/getSystemFinancials',
	async body => {
		const response = await axios.get(
			`${api_domain}meganet/finance/statistics`
		)
		const data = response.data.system_financial
		return data === undefined ? null : data
	}
)

const systemFinancialsAdapter = createEntityAdapter({})

export const {
  selectAll: selectAllSystemFinancials 
} = systemFinancialsAdapter.getSelectors(
		state => state.financeApp.systemFinancials
	)

const systemFinancialsSlice = createSlice({
	name: 'financeApp/systemFinancials',
	initialState: systemFinancialsAdapter.getInitialState(),
	reducers: {},
	extraReducers: {
		[getFilteredSystemFinancial.fulfilled]: (state, action) => {
			if (!action.payload) {
				systemFinancialsAdapter.setAll(state, {})
			} else {
				systemFinancialsAdapter.setAll(state, action.payload)
			}
		},
		[getSystemFinancials.fulfilled]: (state, action) => {
			if (!action.payload) {
				systemFinancialsAdapter.setAll(state, {})
			} else {
				systemFinancialsAdapter.setAll(state, action.payload)
			}
		},
	},
})

export default systemFinancialsSlice.reducer

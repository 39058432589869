import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import axios, { getAdapter } from "axios";
import jwtServiceConfig from "src/app/auth/services/jwtService/jwtServiceConfig";
import toast from "react-hot-toast";
import Alert from "@mui/material/Alert";

const api_domain = jwtServiceConfig.apiUrl;

export const getList = createAsyncThunk(
  "financeApp/balance/getList",
  async (body) => {
    try {
      let url = "";
      if (body.user.role == "betshop_operator") {
        if (body.type == "admin") {
          url = `${api_domain}betshop/admin/list`;
        } else if (body.type == "supplier") {
          url = `${api_domain}betshop/supplier/list/`;
          if (body.adminId)
            url = `${api_domain}betshop/supplier/list/${body.adminId}`;
        } else if (body.type == "user") {
          url = `${api_domain}betshop/user/list/`;
          if (body.adminId)
            url = `${api_domain}betshop/user/list/${body.adminId}`;
          if (body.supplierId)
            url = `${api_domain}betshop/user/list/${body.adminId}/${body.supplierId}`;
        }
      } else if (body.user.role == "betshop_admin") {
        if (body.type == "supplier") {
          url = `${api_domain}betshop_admin/supplier/list/${body.user.uuid}`;
        } else if (body.type == "user") {
          url = `${api_domain}betshop_admin/user/list/${body.user.uuid}`;
          if (body.supplierId)
            url = `${api_domain}betshop_admin/user/list/${body.user.uuid}/${body.supplierId}`;
        }
      } else if (body.user.role == "betshop_supplier") {
        url = `${api_domain}betshop_supplier/user/list/${body.user.uuid}/${body.user.uuid}`;
      }
      const response = await axios.get(url);
      let data = {};
      if (body.type == "admin") {
        data = response.data.betshop_admin;
      } else if (body.type == "supplier") {
        data = response.data.betshop_suppliers;
      } else if (body.type == "user") {
        data = response.data.users;
      }
      return data;
    } catch (error) {
      console.error(error);
    }
  }
);

export const addBalance = createAsyncThunk(
  "financeApp/balance/addBalance",
  async (body) => {
    try {
      console.log("BODY", body)
      let url = "";
      if (body.role == "betshop_operator") {
        if (body.type == "admin") {
          url = `${api_domain}betshop/admin/addBalance`;
        } else if (body.type == "supplier") {
          url = `${api_domain}betshop/supplier/addBalance`;
        } else if (body.type == "user") {
          url = `${api_domain}betshop/user/addBalance`;
        }
      } else if (body.role == "betshop_admin") {
        if (body.type == "supplier") {
          url = `${api_domain}betshop_admin/supplier/addBalance`;
        } else if (body.type == "user") {
          url = `${api_domain}betshop_admin/user/addBalance`;
        }
      } else if (body.role == "betshop_supplier") {
        if (body.type == "user") {
          url = `${api_domain}betshop_supplier/user/addBalance`;
        }
      }

      const response = await axios.put(url, body);
      const data = await response.data;
      if (body.transaction_type == "Deposit") {
        toast.custom(
          <Alert severity="success">Balance has been successfully added</Alert>
        );
      } else {
        toast.custom(
          <Alert severity="success">
            Balance has been successfully withdrawn
          </Alert>
        );
      }
      return data;
    } catch (err) {
      toast.custom(
        <Alert severity="error">{err.response.data.user_error}</Alert>
      );
    }
  }
);

const balanceAdapter = createEntityAdapter({});

export const { selectAll: selectAllList } = balanceAdapter.getSelectors(
  (state) => state.financeApp.system
);

const balanceSlice = createSlice({
  name: "financeApp/balance",
  initialState: balanceAdapter.getInitialState(),
  reducers: {
    setAll: balanceAdapter.setAll,
  },
  extraReducers: {
    [getList.fulfilled]: (state, action) => {
      if (!action.payload) {
        balanceAdapter.setAll(state, []);
      } else {
        balanceAdapter.setAll(state, action.payload);
      }
    },
  },
});

export default balanceSlice.reducer;

import {
	createAsyncThunk,
	createSlice,
	createEntityAdapter,
} from '@reduxjs/toolkit'
import axios from 'axios'
import { showMessage } from 'app/store/fuse/messageSlice'
import settingsConfig from 'app/configs/settingsConfig'
import jwtServiceConfig from 'src/app/auth/services/jwtService/jwtServiceConfig'

const api_domain = jwtServiceConfig.apiUrl

export const getFilteredSystemFinancial = createAsyncThunk(
	'financeApp/systemFinancial/getFilteredSystemFinancial',
	async body => {
		const response = await axios.get(
			`${api_domain}meganet/finance/${body.systemType}/${body.game}/${body.startDate}/${body.endDate}`
		)
		const data = response.data.system_financial
		console.log('DATATATAA', data)
		return data === undefined ? null : data
	}
)
const systemFinancialAdapter = createEntityAdapter({})
export const { selectAll: selectAllSystemFinancial } =
	systemFinancialAdapter.getSelectors(state => state.financeApp.systemFinancial)
const systemFinancialSlice = createSlice({
	name: 'financeApp/systemFinancial',
	initialState: systemFinancialAdapter.getInitialState(),
	reducers: {
		setAll: systemFinancialAdapter.setAll,
	},
	extraReducers: {
		[getFilteredSystemFinancial.fulfilled]: (state, action) => {
			console.log('PAYLOAD', action.payload)
			console.log('PAYLOAD1231231')
			console.log('STATEETE', state)
			if (!action.payload) {
				systemFinancialAdapter.setAll(state, [])
			} else {
				systemFinancialAdapter.setAll(state, action.payload)
			}
		},
	},
})

export const selectCoupon = ({ financeApp }) => financeApp.systemFinancials

export default systemFinancialSlice.reducer

import { lazy } from 'react';
import authRoles from "../../auth/authRoles";

const AccessLogs = lazy(() => import('./access_logs/AccessLogs'));

const accesslogsConfigs = [{
  settings: {
    layout: {},
  },
  //auth: authRoles.account_user,
  routes: [
    {
      path: 'accesslogs',
      element: <AccessLogs />,
    },
  ],
}];

export default accesslogsConfigs;
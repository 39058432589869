import { lazy } from 'react';
import authRoles from "../../auth/authRoles";

const Affiliates = lazy(() => import('./affiliates/index'));
const Affiliate = lazy(() => import('./affiliate/index'));
const OnlinePlayers = lazy(() => import('./online_players/OnlinePlayers'));
const DetailMenu = lazy(() => import('./online_players/OnlinePlayersDetails/DetailsMenu'));
const PlayerInfo = lazy(() => import('./online_players/OnlinePlayersDetails/Info/Info'));

const Admin = lazy(() => import('./admin/Admin'));
const AdminDetailMenu = lazy(() => import('./admin/AdminDetails/DetailsMenu'));
const Details = lazy(() => import('./admin/AdminDetails/Details/Details'));
const Suppliers = lazy(() => import('./suppliers/Suppliers'));
const SuppliersDetailsMenu = lazy(() => import('./suppliers/SuppliersDetails/DetailsMenu'));
const SuppliersInfo = lazy(() => import('./suppliers/SuppliersDetails/Info/Info'));
const Users = lazy(() => import('./users/Users'));
const UsersDetailsMenu = lazy(() => import('./users/UsersDetails/DetailsMenu'));
const UsersInfo = lazy(() => import('./users/UsersDetails/Info/Info'));

const accountsConfigs = [{
  settings: {
    layout: {},
  },
  //auth: authRoles.manager,
  routes: [
    {
      path: 'accounts/online_players',
      element: <OnlinePlayers />,
    },
    {
      path: 'accounts/online_players/:playerId/*',
      element: <Affiliate />,
    },
    {
      path: 'accounts/affiliates',
      element: <Affiliates />,
    },
    {
      path: 'accounts/affiliate/:affiliateId/*',
      element: <Affiliate />,
    },
    {
      path: 'accounts/online_players/sm/',
      element: <DetailMenu />,
      children: [
        {
          path: 'info/:playerId',
          element: <PlayerInfo />
        }
      ]
    },
    {
      path: 'accounts/admin',
      element: <Admin />,
    },
    {
      path: 'accounts/admin/sm/',
      element: <AdminDetailMenu />,
      children: [
        {
          path: 'detail/:userId',
          element: <Details />
        }
      ]
    },
    {
      path: 'accounts/suppliers',
      element: <Suppliers />,
    },
    {
      path: 'accounts/suppliers/sm/',
      element: <SuppliersDetailsMenu />,
      children: [
        {
          path: 'detail/:userId',
          element: <SuppliersInfo />
        }
      ]
    },
    {
      path: 'accounts/users',
      element: <Users />,
    },
    {
      path: 'accounts/users/sm/',
      element: <UsersDetailsMenu />,
      children: [
        {
          path: 'detail/:userId',
          element: <UsersInfo />
        }
      ]
    },
  ],
}];

export default accountsConfigs;
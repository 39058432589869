import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import authRoles from "../../auth/authRoles";

const System = lazy(() => import('./system/SystemTable'));
const SystemFinancial = lazy(() => import('./system_financial/SystemFinancial'));
const TransactionsLogs = lazy(() => import('./transactions_logs/TransactionsLogs'));
const BalanceMenu = lazy(() => import('./balance_deposit_withdraw/Menu'));
const AdminBalance = lazy(() => import('./balance_deposit_withdraw/AdminBalance/AdminBalance'));
const FinancailReport = lazy(() => import('./financial_report/FinancialReport'));
const TransactionLogs = lazy(() => import('./transaction_logs/TransactionLogs'));

const financeConfigs = [{
  settings: {
    layout: {},
  },
  // auth: authRoles.finance_staff,
  routes: [
    {
      path: 'finance/system',
      element: <System />,
    },
    {
      path: 'finance/system_financial',
      element: <SystemFinancial />,
    },
    {
      path: 'finance/transactions_logs',
      element: <TransactionsLogs />,
    },
    {
      path: 'finance/balance_details/sm/',
      element: <BalanceMenu />,
      children: [
        {
          path: 'balance',
          element: <AdminBalance />
        }
      ]
    },
    {
      path: 'finance/financial_report',
      element: <FinancailReport />,
    },
    {
      path: 'finance/transaction_logs',
      element: <TransactionLogs />,
    },
  ],
}];

export default financeConfigs;

import CloseIcon from "@mui/icons-material/Close";
import DoNotDisturbOnOutlinedIcon from "@mui/icons-material/DoNotDisturbOnOutlined";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import {
  Collapse,
  Divider,
  Drawer,
  Grid,
  IconButton,
  Stack,
  TableHead,
  Tooltip,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import format from "date-fns/format";
import { SortTableHead } from "app/shared-components/SortTableHead/SortTableHead";
import { useState } from "react";
import { ifIncludes, isEqual } from "src/app/utils/string";
// import { Chip } from "./Chip";
import * as React from "react";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { SelectGroup } from "app/shared-components/SelectGroup";
import { useScreenMd } from "src/app/hooks/useScreens";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const outcomeOptions = [
  { label: "Unsettled", value: "unsettled" },
  { label: "Won", value: "won" },
  { label: "HalfWon", value: "half-won" },
  { label: "Refunded", value: "refunded" },
  { label: "HalfRefunded", value: "half-refunded" },
  { label: "Lost", value: "lost" },
]

const tableHeaders = [
  { id: "event_id", title: "Betslip ID" },
  { id: "start_date", title: "Date", wrapper: (value) => format(new Date(value), "yyyy MMM dd hh:mm") },
  { id: "event_name", title: "Event Name" },
  { id: "sport_type", title: "Sport Type" },
  { id: "country", title: "" },
  { id: "league_tournament", title: "Currency" },
  { id: "active", title: "Active" },
];

const OutcomeSelect = ({ label }) => {
  return (
    <Box className="flex items-stretch gap-[4px]">
      <Box className="min-w-[120px] border rounded-[12px] flex justify-center items-center">{label}</Box>
      <SelectGroup options={outcomeOptions} />
    </Box>
  )
}

export default function ResultTable(props) {
  const { rows, headCells, filter } = props;
  const {
    Betslip_Id,
    admins,
    suppliers,
    Username,
    Currency,
    Coupon_type,
    Game_type,
    Status,
  } = filter;

  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const [open, setOpen] = React.useState(false);
  const [couponUserDetails, setCouponUserDetails] = useState();
  const [columns, setColumns] = useState([]);
  const [winningRecordsVisible, setWinningRecordsVisible] = useState(false);
  const md = useScreenMd();

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleClick = (event, row) => {
    setOpen(true);
    setCouponUserDetails(row);

    let combs = []    
    for (let i = 2; i < row.coupon_details.length; i++) {
      let res = combinations(row.coupon_details, i);
      combs = combs.concat(res);
    }
    setColumns(combs);
  }; 

  const combinations = (arr, k) => {
    const result = [];
    
    function helper(start, combo) {
        if (combo.length === k) {
            result.push([...combo]);
            return;
        }
        for (let i = start; i < arr.length; i++) {
            combo.push(arr[i]);
            helper(i + 1, combo);
            combo.pop();
        }
    }
    
    helper(0, []);
    return result;
}

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleColumnStatus = (column) => {
    let status = "Won";
    for (let i = 0; i < column.length; i++) {
      if (column[i].bet_status == "Waiting") {
        status = "Waiting";
        break;
      }
    }
    for (let i = 0; i < column.length; i++) {
      if (column[i].bet_status == "Lost") {
        status = "Lost";
        break;
      }
    }
    return status
  }

  const handleColumnWinnings = (column) => {
    let winnings = 1.0;
    for (let i = 0; i < column.length; i++) {
      winnings *= column[i].odds_price;
    }
    return winnings.toFixed(2);
  }

  const isSelected = (id) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const filteredRows = React.useMemo(
    () => rows,
    
    // () =>
    //   rows.filter((row) => {
    //     const {
    //       id: rBetslipID,
    //       username: rUsername,
    //       currency: rCurrency,
    //       coupon_type: rCouponType,
    //       game_type: rGameType,
    //       status: rStatus,
    //     } = row;

    //     return (
    //       ifIncludes(rBetslipID.toString(), Betslip_Id) &&
    //       ifIncludes(rUsername, Username) &&
    //       (Currency === "all" || isEqual(rCurrency, Currency)) &&
    //       (Coupon_type === "all" || isEqual(rCouponType, Coupon_type)) &&
    //       (Game_type === "all" || isEqual(rGameType, Game_type)) &&
    //       (Status === "all" || isEqual(rStatus, Status))
    //     );
    //   }),
    [rows, filter]
  );

  const visibleRows = React.useMemo(
    () =>
      stableSort(filteredRows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage, filteredRows]
  );

  return (
    <Box className="max-w-[1600px] mx-auto">
      <Paper className="w-full mb-[24px] overflow-hidden">
        <TableContainer>
          <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
            {md && (
              <SortTableHead
                headCells={headCells}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
              />
            )}
            <TableBody>
              {visibleRows.map((row, index) => {
                const isItemSelected = isSelected(row.id);
                return md ? (
                  <TableRow
                    hover
                    onClick={(event) => handleClick(event, row)}
                    role="checkbox"
                    tabIndex={-1}
                    key={row.id}
                    selected={isItemSelected}
                    className="cursor-pointer"
                  >
                    <TableCell
                      sx={{
                        py: "4px",
                        pr: "36px",
                        fontSize: "12px",
                        textAlign: "right",
                      }}
                    >
                      {row.event_id}
                    </TableCell>
                    <TableCell sx={{ py: "4px", fontSize: "12px" }}>
                      {format(new Date(row.start_date), "yyyy MMM dd hh:mm")}
                    </TableCell>
                    <TableCell sx={{ py: "4px", fontSize: "12px" }}>
                      {row.event_name}
                    </TableCell>
                    <TableCell sx={{ py: "4px", fontSize: "12px" }}>
                      {row.sport_type}
                    </TableCell>
                    <TableCell
                      sx={{ py: "4px", fontSize: "12px", textAlign: "left" }}
                    >
                      {row.country}
                    </TableCell>
                    <TableCell
                      sx={{ py: "4px", fontSize: "12px", textAlign: "left" }}
                    >
                      {row.league_tournament}
                    </TableCell>
                    <TableCell sx={{ py: "4px", fontSize: "12px", textAlign: "right" }}>
                      Details
                      <IconButton>
                        <AddCircleOutlineIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ) : (
                  <Box className="py-[12px]">
                    {visibleRows.map((row, index) => {
                      const isItemSelected = isSelected(row.id);
                      return (
                        <Box key={index} className="px-[12px] border-b last:border-b-0 py-[8px]">
                          {tableHeaders.map(({ id, title, wrapper }) => (
                            <Box key={id} className="flex items-center w-full py-[4px]">
                              <Box className="w-1/3">{title}</Box>
                              <Box className="w-2/3">{wrapper ? wrapper(row[id]) : row[id]}</Box>
                            </Box>
                          ))}
                        </Box>
                      );
                    })}
                  </Box>
                );
              })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 53 * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <Drawer
        anchor="right"
        open={open}
        onClose={() => setOpen(false)}
        sx={{ ".MuiPaper-root": { overflow: "scroll" } }}
      >
        <Box className="relative">
          <IconButton
            className="absolute -left-[40px] top-0 text-[#111827]"
            onClick={() => setOpen(false)}
          >
            <CloseIcon />
          </IconButton>
          <Stack spacing="12px">
            <Box
              aria-label="slipHeader"
            >
              <Stack
                direction="row"
                spacing="36px"
                className="px-[24px] py-[24px] bg-[#2C374F] text-white"
                justifyContent="space-between"
                alignItems="flex-end"
              >
                <Typography className="text-[18px] font-semibold">Turan Tovus - Kapaz</Typography>
                <Typography>Azerbayjan / Premier League</Typography>
                <Typography>{format(new Date(), "yyy MMM dd, hh:mm")}</Typography>
              </Stack>
              <Divider />
              <Stack gap="12px" className="py-[8px] px-[16px] text-[#111827]">
                <Typography className="text-[16px] font-semibold">Markets</Typography>
                <Box className="flex items-center gap-[12px]">
                  <Box className="w-[200px]">Match Result (1X2)</Box>
                  <Box className="flex-grow">
                    <Box className="flex justify-between gap-[24px]">
                      <OutcomeSelect label="Turan Tovuz" />
                      <OutcomeSelect label="X" />
                      <OutcomeSelect label="Kapaz" />
                    </Box>
                  </Box>
                </Box>
                <Box className="flex items-center gap-[12px]">
                  <Box className="w-[200px]">Total (2.5)</Box>
                  <Box className="flex-grow">
                    <Box className="flex justify-between gap-[24px]">
                      <OutcomeSelect label="Under (2.5)" />
                      <OutcomeSelect label="Over (2.5)" />
                    </Box>
                  </Box>
                </Box>
                <Box className="flex items-center gap-[12px]">
                  <Box className="w-[200px]">Match Result (1X2)</Box>
                  <Box className="flex-grow">
                    <Box className="flex justify-between gap-[24px]">
                      <OutcomeSelect label="Turan Tovuz" />
                      <OutcomeSelect label="X" />
                      <OutcomeSelect label="Kapaz" />
                    </Box>
                  </Box>
                </Box>
                <Box className="flex items-center gap-[12px]">
                  <Box className="w-[200px]">Total (2.5)</Box>
                  <Box className="flex-grow">
                    <Box className="flex justify-between gap-[24px]">
                      <OutcomeSelect label="Under (2.5)" />
                      <OutcomeSelect label="Over (2.5)" />
                    </Box>
                  </Box>
                </Box>
              </Stack>
            </Box>
          </Stack>
        </Box>
      </Drawer>
    </Box>
  );
}

import i18n from 'i18next';
// import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import {createAsyncThunk} from "@reduxjs/toolkit";
import axios from "axios";
import jwtServiceConfig from "./app/auth/services/jwtService/jwtServiceConfig";
import FSBackend from 'i18next-fs-backend';
import ChainedBackend from 'i18next-chained-backend';
import HttpBackend from 'i18next-http-backend';

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en: {
    translation: {
      'Welcome to React': 'Welcome to React and react-i18next',
    },
  },
};

/*const api_domain = jwtServiceConfig.apiUrl;
const loadResources = createAsyncThunk('i18n', async (locale:string) => {
    debugger;
    return await axios.get(`${api_domain}theme_dictionary/admin/languageId/${locale}`)
        .then((response) => { return response.data })
        .catch((error) => { console.log(error); });
});

const backendOptions = {
    loadPath: '{{lng}}|{{ns}}',
    request: (options:any, url:any, payload:any, callback:any) => {
        try {
            const [lng] = url.split('|');
            loadResources('en').then((response) => {
            // loadResources(lng).then((response) => {
                callback(null, {
                    data: response,
                    status: 200,
                });
            });
        } catch (e) {
            console.error(e);
            callback(null, {
                status: 500,
            });
        }
    },
};


i18n
    .use(initReactI18next)
    .use(Backend)
    .init({
        backend: backendOptions,
        fallbackLng: "en",
        debug: false,
        load:"languageOnly",
        ns: ["translations"],
        defaultNS: "translations",
        keySeparator: false,
        interpolation: {
            escapeValue: false,
            formatSeparator: ","
        },
        react: {
            wait: true
        }
    });*/

const api_domain = jwtServiceConfig.apiUrl;

/*export const initI18next = (cb) => {
    const i18n = i18next.createInstance()
    i18n.use(ChainedBackend).init({
        initImmediate: true,
        debug: true,
        lng: 'en',
        fallbackLng: 'en',
        preload: ['en', 'tr', 'ar'],
        ns: ['translation'],
        defaultNS: 'translation',
        backend: {
            backends: [
                HttpBackend,
                FSBackend
            ],
            backendOptions: [{
                loadPath: api_domain + 'theme_dictionary/admin/languageId/{{lng}}'
            }/!*,{
                loadPath: './locales_fallback/{{lng}}/{{ns}}.json'
            }*!/]
        }
    }, cb)
}*/

const config = {
    // initImmediate: true,
    debug: false,
    lng: 'en',
    fallbackLng: 'en',
    preload: ['en', 'tr', 'ar'],
    ns: ['translation', 'navigation'],
    defaultNS: 'translation',
    keySeparator: false,
    backend: {
        backends: [
            HttpBackend,
            FSBackend
        ],
        backendOptions: [{
            loadPath: api_domain + 'theme_dictionary/admin/languageId/{{lng}}'
        }/*,{
                loadPath: './locales_fallback/{{lng}}/{{ns}}.json'
            }*/]
    },
    react: {
        useSuspense: false,
    }
}

// initI18next((err, t) => {
/*    if (err) return console.error(err)
    console.log(t('dictionary'))
    console.log(t('dictionary', { lng: 'de' }))
    console.log('stopping http server...')
    server.close(() => {
        console.log('http server stopped')
        initI18next((err, t) => {
            if (err) return console.error(err)
            console.log(t('welcome'))
            console.log(t('welcome', { lng: 'de' }))
        })
    })*/
// })

// let i18n;
// Real
/*i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'en',

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });*/


i18n
    .use(ChainedBackend)
    .init(config, (err, t) => {
        if (err) return console.error(err)
        // console.log(t('welcome'))
        // console.log(t('welcome', { lng: 'de' }))

        /*setInterval(() => {
            i18next.reloadResources().then(() => {
                setImmediate(() => {
                    console.log(t('welcome'))
                    console.log(t('welcome', { lng: 'de' }))
                })
            })
        }, 3000)*/
    });

export default i18n;

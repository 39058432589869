import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import axios from 'axios';
import { showMessage } from 'app/store/fuse/messageSlice';
import jwtServiceConfig from 'src/app/auth/services/jwtService/jwtServiceConfig';
import toast from 'react-hot-toast'
import Alert from '@mui/material/Alert';

const api_domain = jwtServiceConfig.apiUrl;


export const getAffiliate = createAsyncThunk('accountApp/affiliate/getAffiliate', async (affiliateId) => {
  const response = await axios.get(`${api_domain}affiliate/${affiliateId}`);
  const data = await response.data.affiliate;

  return data === undefined ? null : data;
});

export const setVerified = createAsyncThunk(
    'accountApp/affiliate/setVerified',
    // async (token) => {
    async (token, { rejectWithValue, dispatch }) => {
      try {
        const response = await axios.get(`${api_domain}affiliate/setVerified/${token}`);
        const data = await response.data;

        return data === undefined ? null : data;

      } catch (err) {
        console.log(err)
        return rejectWithValue(err.response.data)
      }
  }
);

export const saveAffiliate = createAsyncThunk(
  'accountApp/affiliate/saveAffiliate',
  async (data, { rejectWithValue, dispatch }) => {
    const { id, ...fields } = data
    try {
      const response = await axios.put(`${api_domain}affiliate/save/${id}`, {
        active: data.active,
        agreement_percentage: parseInt(data.agreement_percentage),
        birthday: data.birthday,
        country_id: data.country_id,
        payout_currency: data.payout_currency,
        currencies: data.currencies,
        deduct_negative: data.deduct_negative,
        default_revenue_share: data.default_revenue_share,
        deposit_requirement: parseInt(data.deposit_requirement),
        first_name: data.first_name,
        sur_name: data.sur_name,
        last_name: data.last_name,
        email: data.email,
        gender: data.gender,
        kyc_enabled: data.kyc_enabled,
        network_cost: parseInt(data.network_cost),
        password: data.password,
        phone: data.phone,
        turnover_requirement: parseInt(data.turnover_requirement),
        username: data.username,
        kyc: data.kyc,
        note: data.note,
        address: data.address,
      })
      // dispatch(showMessage({ message: 'Affiliate Saved' }));      
      toast.custom(
        <Alert severity='success'>
          Affiliate Saved
        </Alert>
      )

      return response.data.affiliate

    } catch (err) {
      if (err?.response?.data?.status) {
        // dispatch(showMessage({ message: 'Error: ' + err.response.data.user_error }));

        // dispatch(
        //   showMessage({
        //     message: 'Error: ' + err.response.data.user_error,
        //     autoHideDuration: 2000,
        //     anchorOrigin: {
        //       vertical: 'top',
        //       horizontal: 'center',
        //     },
        //   })
        // );
              
      toast.custom(
        <Alert severity='error'>
          {err.response.data.user_error}
        </Alert>
      )

        // throw err
      }

      return rejectWithValue(err.response.data)
    }
  }
)

const affiliateSlice = createSlice({
  name: 'accountApp/affiliate',
  initialState: null,
  reducers: {
    resetAffiliate: () => null,
    newAffiliate: {
      reducer: (state, action) => action.payload,
      prepare: (event) => {
        return {
          payload: {
            newId: '',
            id: "new",
            first_name: '',
            sur_name: '',
            last_name: '',
            username: '',
            email: '',
            password: '',
            phone: '',
            payout_currency: '',
            currencies: [],
            country_id: 1,
            gender: '',
            default_revenue_share: '',
            agreement_percentage: '',
            network_cost: '',
            turnover_requirement: '',
            deposit_requirement: '',
            note: '',
            address: '',
            active: true,
            deduct_negative: true,
            kyc: true,
            owner: {}
          }
        }
      },
    },
  },
  extraReducers: {
    [getAffiliate.fulfilled]: (state, action) => action.payload,
    [saveAffiliate.fulfilled]: (state, action) => action.payload,
    [setVerified.fulfilled]: (state, action) => action.payload,
    /*[saveAffiliate.fulfilled]: (state, action) => {
      state.newId = action.payload.id
    },*/
  },
});

export const { newAffiliate, resetAffiliate } = affiliateSlice.actions;

export const selectAffiliate = ({ accountApp }) => accountApp.affiliate;

export default affiliateSlice.reducer;


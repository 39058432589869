import _ from '@lodash';
import FuseUtils from '@fuse/utils';
import mockApi from '../mock-api.json';
import mock from '../mock';
import jwtServiceConfig from 'src/app/auth/services/jwtService/jwtServiceConfig';

const api_domain = jwtServiceConfig.apiUrl;

let notificationsDB = mockApi.components.examples.notifications.value;

mock.onGet(`${api_domain}/api/notifications`).reply((config) => {
  return [200, notificationsDB];
});

mock.onDelete(`${api_domain}/api/notifications`).reply((config) => {
  notificationsDB = [];
  return [200];
});

mock.onPost(`${api_domain}/api/notifications`).reply(({ data }) => {
  const newNotification = { id: FuseUtils.generateGUID(), ...JSON.parse(data) };

  notificationsDB.push(newNotification);

  return [200, newNotification];
});

mock.onDelete(/\/api\/notifications\/[^/]+/).reply((config) => {
  const { id } = config.url.match(/\/api\/notifications\/(?<id>[^/]+)/).groups;

  _.remove(notificationsDB, { id });

  return [200, id];
});

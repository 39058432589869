import FuseUtils from "@fuse/utils/FuseUtils";
import Alert from "@mui/material/Alert";
import axios from "axios";
import jwtDecode from "jwt-decode";
import toast from "react-hot-toast";
import jwtServiceConfig from "./jwtServiceConfig";
/* eslint-disable camelcase */

class JwtService extends FuseUtils.EventEmitter {
  init() {
    this.setInterceptors();
    this.handleAuthentication();
  }

  setInterceptors = () => {
    axios.interceptors.response.use(
      (response) => {
        // toast.custom(
        // 	<Alert severity='success'>
        // 		Fetched data from server successfully
        // 	</Alert>
        // )
        return response;
      },
      (err) => {
        return new Promise((resolve, reject) => {
          // toast.custom(<Alert severity='error'>{err.message}</Alert>)
          if (
            err.response.status === 401 &&
            err.config &&
            !err.config.__isRetryRequest
          ) {
            // if you ever get an unauthorized response, logout the user
            this.emit("onAutoLogout", "Invalid access_token");
            this.setSession(null);
          }
          throw err;
        });
      }
    );
  };

  handleAuthentication = () => {
    const access_token = this.getAccessToken();

    if (!access_token) {
      this.emit("onNoAccessToken");

      return;
    }

    if (this.isAuthTokenValid(access_token)) {
      this.setSession(access_token);
      this.emit("onAutoLogin", true);
    } else {
      this.setSession(null);
      this.emit("onAutoLogout", "access_token expired");
    }
  };

  createUser = (data) => {
    return new Promise((resolve, reject) => {
      axios.post(jwtServiceConfig.signUp, data).then((response) => {
        if (response.data.user) {
          this.setSession(response.data.access_token);
          resolve(response.data.user);
          this.emit("onLogin", response.data.user);
        } else {
          reject(response.data.error);
        }
      });
    });
  };

  createAffiliateUser = (data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(jwtServiceConfig.signUpAffiliate, data)
        .then((response) => {
          const user = {
            uuid: response.data.user.uuid,
            from: "db",
            role: response.data.user.role,
            data: {
              displayName: response.data.user.data.displayName,
              photoURL: response.data.user.data.photoURL,
              email: response.data.user.data.email,
              currency: response.data.user.data.currency,
              accountBalance: response.data.user.data.accountBalance.toFixed(2),
              // "settings": response.data.user.data.settings,
              settings: {},
              shortcuts: response.data.user.data.shortcuts,
            },
            permissions: response.data.user.permissions,
          };
          if (user) {
            this.setSession(response.data.access_token);
            resolve(user);
            this.emit("onLogin", user);
            // navigate(`/dashboards`);
          } else {
            reject(response.data.error);
          }

          /*if (response.data.user) {
          this.setSession(response.data.access_token);
          resolve(response.data.user);
          this.emit('onLogin', response.data.user);
        } else {
          reject(response.data.error);
        }*/
        })
        .catch((error) => {
          const errors = [];
          errors.push({
            type: "email",
            message: error.response.data.user_error,
          });

          reject(errors);
        });
    });
  };

  signInWithUserAndPassword = (user, password) => {
    return new Promise((resolve, reject) => {
      axios
        .post(jwtServiceConfig.signIn, {
          user,
          password,
        })
        .then((response) => {
          const user = {
            uuid: response.data.user.uuid,
            from: "db",
            role: response.data.user.role,
            data: {
              displayName: response.data.user.data.displayName,
              photoURL: response.data.user.data.photoURL,
              email: response.data.user.data.email,
              currency: response.data.user.data.currency,
              accountBalance: response.data.user.data.accountBalance.toFixed(2),
              // "settings": response.data.user.settings,
              settings: {},
              shortcuts: response.data.user.shortcuts,
            },
            permissions: response.data.user.permissions,
            partnerName: response.data.user.partner_name,
            currencyForReports: response.data.user.currency_for_reports,
            notificationSubs: response.data.user.notification_subs,
            timezone: response.data.user.timezone,
          };
          if (user) {
            this.setSession(response.data.access_token);
            resolve(user);
            this.emit("onLogin", user);
          } else {
            reject(response.data.error);
          }
        })
        .catch((error) => {
          toast.custom(
            <Alert severity="error">{error.response.data.user_error}</Alert>
          );
          const errors = [];
          errors.push({
            type: "email",
            message: error.response.data.user_error,
          });
          reject(errors);
        });
    });
  };

  signInWithToken = () => {
    return new Promise((resolve, reject) => {
      axios
        .post(jwtServiceConfig.accessToken, {
          data: {
            access_token: this.getAccessToken(),
          },
        })
        .then((response) => {
          const user = {
            uuid: response.data.user.uuid,
            from: "db",
            role: response.data.user.role,
            data: {
              displayName: response.data.user.data.displayName,
              photoURL: response.data.user.data.photoURL,
              email: response.data.user.data.email,
              currency: response.data.user.data.currency,
              accountBalance: response.data.user.data.accountBalance.toFixed(2),
              settings: {},
              // "settings": response.data.user.settings,
              shortcuts: response.data.user.data.shortcuts,
            },
            permissions: response.data.user.permissions,
            partnerName: response.data.user.partner_name,
            currencyForReports: response.data.user.currency_for_reports,
            notificationSubs: response.data.user.notification_subs,
            timezone: response.data.user.timezone,
          };

          if (user) {
            this.setSession(response.data.access_token);
            resolve(user);
          } else {
            this.logout();
            reject(new Error("Failed to login with token."));
          }
        })
        .catch((error) => {
          this.logout();
          reject(new Error("Failed to login with token."));
        });
    });
  };

  updateUserData = (user) => {
    return axios.post(jwtServiceConfig.updateUser, {
      user,
    });
  };

  setSession = (access_token) => {
    if (access_token) {
      localStorage.setItem("jwt_access_token", access_token);
      axios.defaults.headers.common.Authorization = `Bearer ${access_token}`;
    } else {
      localStorage.removeItem("jwt_access_token");
      delete axios.defaults.headers.common.Authorization;
    }
  };

  logout = () => {
    this.setSession(null);
    this.emit("onLogout", "Logged out");
  };

  isAuthTokenValid = (access_token) => {
    if (!access_token) {
      return false;
    }
    const decoded = jwtDecode(access_token);
    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
      console.warn("access token expired");
      return false;
    }

    return true;
  };

  getAccessToken = () => {
    return window.localStorage.getItem("jwt_access_token");
  };
}

const instance = new JwtService();

export default instance;

import {
  createAsyncThunk,
  createSlice,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import axios from "axios";
import jwtServiceConfig from "src/app/auth/services/jwtService/jwtServiceConfig";

const api_domain = jwtServiceConfig.apiUrl;

export const getFinancialReport = createAsyncThunk(
  "financeApp/financialReport/getFinancialReport",
  async (body) => {
    let url = "";
    if (body.role == "betshop_operator") {
      url = `${api_domain}betshop/finance/statistics`;
      if (body.admin)
        url = `${api_domain}betshop/finance/statistics/${body.admin}/${body.startDate}/${body.endDate}`;
    } else if (body.role == "betshop_admin") {
      url = `${api_domain}betshop_admin/finance/statistics`;
    } else if (body.role == "betshop_supplier") {
      url = `${api_domain}betshop_supplier/finance/statistics/${body.supplierId}`;
      if (body.startDate) {
        url = `${api_domain}betshop_supplier/finance/statistics/${body.supplierId}/${body.startDate}/${body.endDate}`;
      }
    }
    const response = await axios.get(url);
    const data = response.data.betshop_financial;
    return data === undefined ? null : data;
  }
);

export const getAdminList = createAsyncThunk(
  "financeApp/balance/getList",
  async (body) => {
    try {
      const response = await axios.get(
        `${api_domain}betshop/finance/admin/list`
      );
      const data = response.data.betshop_admin;
      return data;
    } catch (error) {
      console.error(error);
    }
  }
);

const financialReportAdapter = createEntityAdapter({});
export const { selectAll: selectAllFinancialReport } =
  financialReportAdapter.getSelectors(
    (state) => state.financeApp.financialReport
  );
const financialReportSlice = createSlice({
  name: "financeApp/financialReport",
  initialState: financialReportAdapter.getInitialState(),
  reducers: {
    setAll: financialReportAdapter.setAll,
  },
  extraReducers: {
    [getFinancialReport.fulfilled]: (state, action) => {
      console.log(state);
      if (!action.payload) {
        financialReportAdapter.setAll(state, []);
      } else {
        financialReportAdapter.setAll(state, action.payload);
      }
    },
  },
});

export default financialReportSlice.reducer;

import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import axios from "axios";
import { authRoles } from "src/app/auth";
import jwtServiceConfig from "src/app/auth/services/jwtService/jwtServiceConfig";

const api_domain = jwtServiceConfig.apiUrl;

export const getVendors = createAsyncThunk(
  "betshopAdmin/getVendors",
  async (role) => {   
    let url = "";
    if (authRoles.online.includes(role))
      url = `${api_domain}online/vendors/getAll`;
    else url = `${api_domain}betshop/vendors/getAll`;
    const { data } = await axios.get(url);
    return data.vendors || [];
  }
);

export const getCasinoReports = createAsyncThunk(
  "betshopAdmin/getCasinoReports",
  async ({ user, filter }) => {
    const {
      startDate,
      endDate,
      admin,
      supplier,
      user: username,
      vendor,
      gameType: game,
    } = filter;
    if (authRoles.online.includes(user.role)) {
      let res = [];
      const gameType = game === "all" ? "all" : game;
      const { data } = await axios.get(
        `${api_domain}online/report/casino/${startDate}/${endDate}/${username}/${vendor}/${game}`
      );
      return (
        {
          casino_report: data.casino_report,
          total_profit: data.total_profit,
        } || {
          casino_report: [],
          total_profit: 0,
        }
      );
    } else {
      // betshop
      try {
        let res = [];
        const gameType = game === "all" ? "all" : game;

        if (admin === "all") {
          res = await axios.get(
            `${api_domain}betshop/report/casino/${startDate}/${endDate}/${vendor}/${gameType}`
          );
        } else if (supplier === "all") {
          res = await axios.get(
            `${api_domain}betshop/report/casino/${startDate}/${endDate}/admin/${admin}/${vendor}/${gameType}`
          );
        } else if (username === "") {
          res = await axios.get(
            `${api_domain}betshop/report/casino/${startDate}/${endDate}/supplier/${supplier}/${vendor}/${gameType}`
          );
        } else {
          res = await axios.get(
            `${api_domain}betshop/report/casino/${startDate}/${endDate}/user/${username}/${vendor}/${gameType}`
          );
        }
        return (
          {
            casino_report: res.data.casino_report,
            total_profit: res.data.total_profit,
          } || {
            casino_report: [],
            total_profit: 0,
          }
        );
      } catch (_) {
        //
        return [];
      }
    }
  }
);

const reportAdapter = createEntityAdapter();

export const { selectAll: selectCasinoReports } = reportAdapter.getSelectors(
  (state) => state.betshopAdmin.casinoReports
);

const reportSlice = createSlice({
  name: "betshopAdmin/casinoReports",
  initialState: reportAdapter.getInitialState({
    casinoReports: [],
    totalProfit: 0,
  }),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCasinoReports.fulfilled, (state, action) => {
      reportAdapter.setAll(state, action.payload.casino_report);
      state.totalProfit = action.payload.total_profit;
    });
  },
});
export const selectTotalProfit = ({ betshopAdmin }) =>
  betshopAdmin.casinoReports.totalProfit;
export default reportSlice.reducer;

import FuseSplashScreen from "@fuse/core/FuseSplashScreen";
import { Alert, Snackbar } from "@mui/material";
import { logoutUser, setUser } from "app/store/userSlice";
import * as React from "react";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import jwtService from "./services/jwtService";
import { showMessage } from 'app/store/fuse/messageSlice';

const AuthContext = React.createContext();

function AuthProvider({ children }) {
  const [alert, setAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("");
  const [isAuthenticated, setIsAuthenticated] = useState(undefined);
  const [waitAuthCheck, setWaitAuthCheck] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    jwtService.on("onAutoLogin", () => {
      // dispatch(showMessage({ message: 'Signing in with JWT' }));
      // dispatch(showMessage({ message: '' }));

      /**
       * Sign in and retrieve user data with stored token
       */
      jwtService
        .signInWithToken()
        .then((user) => {
          // success(user, "Signed in with JWT");
          success(user, "");
        })
        .catch((error) => {
          pass(error.message);
        });
    });

    jwtService.on("onLogin", (user) => {
      // setAlertType("success");
      success(user, "Signed in");
    });

    jwtService.on("onLogout", () => {
      pass("Signed out");

      dispatch(logoutUser());
    });

    jwtService.on("onAutoLogout", (message) => {
      pass(message);

      dispatch(logoutUser());
    });

    jwtService.on("onNoAccessToken", () => {
      pass();
    });

    jwtService.init();

    function success(user, message) {
      if (message) {
        setAlertMessage(message);
        setAlert(true);
        //dispatch(showMessage({ message }));
      }

      Promise.all([
        dispatch(setUser(user)),
        // You can receive data in here before app initialization
      ]).then((values) => {
        setWaitAuthCheck(false);
        setIsAuthenticated(true);
      });
    }

    function pass(message) {
      if (message) {
        // dispatch(showMessage({ message }));
        toast.custom(<Alert variant="filled" severity="success">{message}</Alert>);
      }

      setWaitAuthCheck(false);
      setIsAuthenticated(false);
    }
  }, [dispatch]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlert(false);
  };

  return waitAuthCheck ? (
    <>
      <FuseSplashScreen />
    </>
  ) : (
    <>
      <AuthContext.Provider value={{ isAuthenticated }}>
        {children}
      </AuthContext.Provider>

      <Snackbar open={alert} autoHideDuration={4000} onClose={handleClose}>
        <Alert variant="filled"
          onClose={handleClose}
          severity={alertType}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {alertMessage}
        </Alert>
      </Snackbar>
    </>
  );
}

function useAuth() {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within a AuthProvider");
  }
  return context;
}

export { AuthProvider, useAuth };

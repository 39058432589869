import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import jwtServiceConfig from 'src/app/auth/services/jwtService/jwtServiceConfig';

const api_domain = jwtServiceConfig.apiUrl;

export const getAllOperatorTransactions = createAsyncThunk('financeApp/transactionsLogs/getAllOperatorTransactions', async () => {
    try {
        const response = await axios.get(`${api_domain}meganet/finance/transactions`);
        const data = await response.data.transactions;
        return data;
    } catch (error) {
        console.error(error)
    }
});

export const getFilteredTransactionsLog = createAsyncThunk('financeApp/transactionsLogs/getFilteredTransactionsLog', async (body) => {
    debugger;
    const response = await axios.get(`${api_domain}meganet/finance/transactions/${body.system}/${body.startDate}/${body.endDate}`);
    const data = response.data.transactions;
    return data;

});

const transactionsLogsAdapter = createEntityAdapter({});

export const {
    selectById: selectDictionaryById,
  selectIds: selectDictionaryIds,
    selectAll: selectAllTransactionsLogs,
} = transactionsLogsAdapter.getSelectors((state) => state.financeApp.transactionsLogs);

const transactionsLogsSlice = createSlice({
    name: 'financeApp/transactionsLogs',
    initialState: transactionsLogsAdapter.getInitialState({}),
    reducers: {},
    extraReducers: {
        [getFilteredTransactionsLog.fulfilled]: (state, action) => {
            if (!action.payload) {
                transactionsLogsAdapter.setAll(state, {});
            } else {
                transactionsLogsAdapter.setAll(state, action.payload);
            }
        },
        [getAllOperatorTransactions.fulfilled]: (state, action) => {
            if (!action.payload) {
                transactionsLogsAdapter.setAll(state, {});
            } else {
                transactionsLogsAdapter.setAll(state, action.payload);
            }
        },
    },
});

export default transactionsLogsSlice.reducer;
import {createAsyncThunk, createSelector, createSlice} from '@reduxjs/toolkit';
import i18n from 'src/i18n';
import { setDefaultSettings } from './fuse/settingsSlice';
import jwtServiceConfig from "../auth/services/jwtService/jwtServiceConfig";

const api_domain = jwtServiceConfig.apiUrl;

export const changeLanguage = (languageId) => (dispatch, getState) => {
  const { direction } = getState().fuse.settings.defaults;

  const newLangDirection = i18n.dir(languageId);

  /*
    If necessary, change theme direction
     */
  if (newLangDirection !== direction) {
    dispatch(setDefaultSettings({ direction: newLangDirection }));
  }

  /*
    Change Language
     */
  return i18n.changeLanguage(languageId).then(() => {
    dispatch(i18nSlice.actions.languageChanged(languageId));
  });
};

const i18nSlice = createSlice({
  name: 'i18n',
  initialState: {
    language: i18n.options.lng,
    languages: [
      { id: 'en', title: 'English', flag: 'US' },
      { id: 'tr', title: 'Türkçe', flag: 'TR' },
      { id: 'ar', title: 'عربي', flag: 'SA' },
      { id: 'es', title: 'Español', flag: 'ES' },
      { id: 'pt', title: 'Português', flag: 'PT' },
      { id: 'ru', title: 'Русский', flag: 'RU' },
      { id: 'fr', title: 'Français', flag: 'FR' },
      { id: 'de', title: 'Deutsch', flag: 'DE' },
      { id: 'zh', title: '中文', flag: 'CN' },
      { id: 'ja', title: '日本語', flag: 'JP' },
      { id: 'it', title: 'Italiano', flag: 'IT' },
      // { id: 'pl', title: 'Polski', flag: 'PL' },
      // { id: 'no', title: 'Norsk', flag: 'NO' },
      // { id: 'vi', title: 'Tiếng Việt', flag: 'VN' },
      // { id: 'ma', title: 'Bahasa Melayu', flag: 'MY' },
      // { id: 'oz', title: 'Oʻzbekcha', flag: 'UZ' },
      { id: 'ko', title: '한국어', flag: 'KR' },
    ],
  },
  reducers: {
    languageChanged: (state, action) => {
      state.language = action.payload;
    },
  },
});

export const selectCurrentLanguageId = ({ i18n: _i18n }) => _i18n.language;

export const selectLanguages = ({ i18n: _i18n }) => _i18n.languages;

export const selectCurrentLanguageDirection = createSelector([selectCurrentLanguageId], (id) => {
  return i18n.dir(id);
});

export const selectCurrentLanguage = createSelector(
  [selectCurrentLanguageId, selectLanguages],
  (id, languages) => {
    return languages.find((lng) => lng.id === id);
  }
);

export default i18nSlice.reducer;

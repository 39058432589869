import { Box, Typography } from "@mui/material";
import Select from "react-select";

export const SelectGroup = ({ value, onChange, label, options = [], multiple = false, fullWidth = false, minWidth }) => {
  return (
    <Box className="flex flex-col items-start gap-[4px]" sx={{ width: fullWidth ? "100%" : "auto", minWidth }}>
      {label && <Typography className="text-[#505155] text-[11px] capitalize min-w-[120px]">{label}</Typography>}
      <Select
        value={options.find((op) => op.value === value)}
        onChange={onChange}
        className="custom-select text-[#333]"
        options={options}
        isSearchable={false}
        isMulti={multiple}
      />
    </Box>
  );
};

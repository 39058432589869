import FuseUtils from '@fuse/utils';
import FuseLoading from '@fuse/core/FuseLoading';
import { Navigate } from 'react-router-dom';
import settingsConfig from 'app/configs/settingsConfig';
import userInterfaceConfigs from '../main/user-interface/UserInterfaceConfigs';
import SignInConfig from '../main/sign-in/SignInConfig';
import AffiliateSignUpConfig from '../main/affiliate-sign-up/AffiliateSignUpConfig';
import SignUpConfig from '../main/sign-up/SignUpConfig';
import SignOutConfig from '../main/sign-out/SignOutConfig';
import dashboardsConfigs from '../main/dashboards/dashboardsConfigs';
import SportDataConfigs from '../main/sport-data/SportDataConfigs';
import casinoConfigs from '../main/casino/casinoConfigs';
import settingsConfigs from '../main/settings/settingsConfigs';
import accountsConfigs from '../main/accounts/accountsConfigs';
import managementConfigs from '../main/management/managementConfigs';
import appsConfigs from '../main/apps/appsConfigs';
import pagesConfigs from '../main/pages/pagesConfigs';
import authRoleExamplesConfigs from '../main/auth/authRoleExamplesConfigs';
import financeConfigs from '../main/finance/financeConfigs';
import AffiliateVerificationConfig from "../main/affiliate-verification/AffiliateVerificationConfig";
import bonusesConfigs from '../main/bonuses/bonusesConfigs';
// import betshopaccountConfigs from '../main/betshop-account/betshopaccountConfigs';
import cmsConfigs from '../main/cms/cmsConfigs';
import betshop_adminConfigs from '../main/betshop-admin/betshop_adminConfig';
import CasinoVendorsAndProvidersConfigs from '../main/Casino-Vendors-and-Providers/CasinoVendorsAndProvidersConfigs';
import accesslogsConfigs from '../main/access-logs/accesslogsConfigs';
import onlineFinanceConfigs from '../main/online_operator/finance/onlineFinanceConfigs';

const routeConfigs = [
  ...appsConfigs,
  ...dashboardsConfigs,
  ...SportDataConfigs,
  ...casinoConfigs,
  ...settingsConfigs,
  ...accountsConfigs,
  ...managementConfigs,
  ...pagesConfigs,
  ...authRoleExamplesConfigs,
  ...userInterfaceConfigs,
  ...financeConfigs,
  // ...betshopaccountConfigs,
  ...bonusesConfigs,
  ...cmsConfigs,
  ...betshop_adminConfigs,
  ...CasinoVendorsAndProvidersConfigs,
  ...accesslogsConfigs,
  ...onlineFinanceConfigs,
  SignOutConfig,
  SignInConfig,
  SignUpConfig,
  AffiliateSignUpConfig,
  AffiliateVerificationConfig,
];

const routes = [
  ...FuseUtils.generateRoutesFromConfigs(routeConfigs, settingsConfig.defaultAuth),
  {
    path: '/',
    element: <Navigate to="dashboards/sport/event" />,
    auth: settingsConfig.defaultAuth,
  },
  {
    path: 'loading',
    element: <FuseLoading />,
  },
  {
    path: '*',
    element: <Navigate to="pages/error/404" />,
  },
];

export default routes;

import i18n from "src/i18n";
import FuseSvgIcon from "@fuse/core/FuseSvgIcon";
import { Box, Button, Typography } from "@mui/material"
import { SelectGroup } from "app/shared-components/SelectGroup"
import { makeStyles } from "@mui/styles";
import CustomDatePicker from "../../../components/date-picker/CustomDatePicker";

const betTypeOptions = [
  { value: "prematch", label: "Prematch" },
  { value: "live", label: "Live" },
]

const resultStatusOptions = [
  { value: "settled", label: "Settled" },
  { value: "unsettled", label: "Unsettled" },
]

export const FilterHeader = ({ onFilterChange }) => {
  const useStyles = makeStyles((theme) => ({
    getButton: {
      // marginTop: theme.spacing(2),
      marginTop: 10,
    },
  }));

  const onSubmit = () => {
    onFilterChange(/** , , , */);
  }
  
  return (
    <Box className="container max-w-[1600px] mx-auto pt-[64px]">
      <Typography className="text-3xl font-semibold">Result Management</Typography>
      <Box className="flex flex-col lg:flex-row gap-[4px] lg:items-end mt-[24px]">
        <CustomDatePicker
          // startDate={startDate}
          // endDate={endDate}
          onChange={(dates) => {
            // setStartDate(dates[0]);
            // setEndDate(dates[1]);
          }}
        />
        <SelectGroup label="Bet Type" minWidth="240px" options={betTypeOptions} />
        <SelectGroup label="Sport" minWidth="240px" />
        <SelectGroup label="Country" minWidth="240px" />
        <SelectGroup label="League" minWidth="240px" />
        <SelectGroup label="Result Status" minWidth="240px" options={resultStatusOptions} />
        <Button
          className={useStyles.getButton}
          variant="contained"
          color="secondary"
          startIcon={
            <FuseSvgIcon>heroicons-outline:chevron-right</FuseSvgIcon>
          }
          onClick={onSubmit}
          sx={{ px: "24px" }}
        >
          {i18n.t("Get")}
        </Button>
      </Box>
    </Box>
  )
}
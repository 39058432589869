import List from '@mui/material/List';
import { styled } from '@mui/material/styles';
import clsx from 'clsx';
import FuseNavItem from '../FuseNavItem';
import { usePermissions } from '../../../../app/hooks/usePermission';

const StyledList = styled(List)(({ theme }) => ({
  '& .fuse-list-item': {
    '&:hover': {
      backgroundColor:
        theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0,0,0,.04)',
    },
    '&:focus:not(.active)': {
      backgroundColor:
        theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.06)' : 'rgba(0,0,0,.05)',
    },
    padding: '8px 12px 8px 12px',
    height: 40,
    minHeight: 40,
    width: 150,
    '&.level-0': {
      minHeight: 44,
      minminHeight: 44,
    },
    '& .fuse-list-item-text': {
      padding: '0 0 0 8px',
    },
  },
  '&.active-square-list': {
    '& .fuse-list-item': {
      borderRadius: '0',
    },
  },
}));

function removeMenuItemById(items, idToRemove) {
  return items.map(item => {
    if (item.children) {
        item.children = item.children.filter(child => child.id !== idToRemove);
    }
    return item;
  }).filter(item => item.id !== idToRemove);
}

const checkHasChildren = (items, id) => {
  const item = items.find(it => it.id === id);
  if (item && item.children.length === 0) {
    return items.filter(item => item.id !== id);
  }
  return items;
}

function FuseNavHorizontalLayout1(props) {
  const { navigation: originNavigation, layout, active, dense, className } = props;
  const {
    affiliatePageView,
    cmsPageView,
    couponManagementPageView,
    depositView,
    withdrawView,
    transactionPageView,
    financialReportPageView,
  } = usePermissions();

  let navigation = originNavigation;
  console.log('@@@navigation', navigation);

  if (!affiliatePageView())
    navigation = removeMenuItemById(navigation, "accounts.affiliates");
  if (!cmsPageView())
    navigation = removeMenuItemById(navigation, "cms");
  if (!couponManagementPageView())
    navigation = removeMenuItemById(navigation, 'management.coupon');
  if (!depositView())
    navigation = removeMenuItemById(navigation, 'finance.deposit');
  if (!withdrawView())
    navigation = removeMenuItemById(navigation, "finance.withdraw");
  if (!transactionPageView())
    navigation = removeMenuItemById(navigation, "finance.transaction");
  if (!financialReportPageView())
    navigation = removeMenuItemById(navigation, "finance.financial_report");

  navigation = checkHasChildren(navigation, "finance");

  return (
    <StyledList
      className={clsx(
        'navigation whitespace-nowrap flex p-0',
        `active-${active}-list`,
        dense && 'dense',
        className
      )}
    >
      {navigation.map((_item) => (
        <FuseNavItem
          key={_item.id}
          type={`horizontal-${_item.type}`}
          item={_item}
          nestedLevel={0}
          dense={dense}
        />
      ))}
    </StyledList>
  );
}

export default FuseNavHorizontalLayout1;

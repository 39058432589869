import Avatar from '@mui/material/Avatar';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import { selectUser, changeUserPassword, changeUserSettings, getUserSettings } from 'app/store/userSlice';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { authRoles } from 'src/app/auth';
import toast from 'react-hot-toast'
import Alert from '@mui/material/Alert';
import avatarImg from "../../../../src/images/avatar.png";

function UserMenu(props) {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const AuthRoles = authRoles;
  const [userMenu, setUserMenu] = useState(null);
  const [curPassword, setCurPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [partnerName, setPartnerName] = useState('')
  const [currencyForReports, setCurrencyForReports] = useState('')
  const [notificationSubs, setNotificationSubs] = useState('')
  const [timezone, setTimezone] = useState('')

  useEffect (() => {
    if( AuthRoles.betshop.includes(user.role)) {
      dispatch(getUserSettings()).then((res) => {
        setPartnerName(res.payload?.partner_name)
        setCurrencyForReports(res.payload?.currency_for_reports)
        setNotificationSubs(res.payload?.notification_subs)
        setTimezone(res.payload?.timezone)
      })
    }
  }, [user])

  const userMenuClick = (event) => {
    setUserMenu(event.currentTarget);
  };

  const userMenuClose = () => {
    setUserMenu(null);
  };

  const handleResetPassSave = () => {
    if(newPassword != confirmPassword) {
      toast.custom(
        <Alert severity='error'>
            Confirm password does not match 
        </Alert>
      )
      return
    }
    const data = {current_password: curPassword, new_password: newPassword,}
    dispatch(changeUserPassword(data)).then((req) => {
      setCurPassword('')
      setNewPassword('')
      setTimezone('')
      handleResetPassClose()
    })
  }

  const handleSettingSave = () => {
    const data = {
      partner_name: partnerName, 
      currency_for_reports: currencyForReports,
      notification_subs: notificationSubs,
      timezone: timezone,
    }
    dispatch(changeUserSettings(data)).then((req) => {
      // setPartnerName('')
      // setCurrencyForReports('')
      // setNotificationSubs('')
      // setTimezone('')
      handleSettingClose()
    })
  }

  const ModalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "95%",
    maxWidth: 650,
    bgcolor: 'background.paper',
    border: '0',
    boxShadow: 24,
    p: 4,
  };

  const [openSetting, setOpenSetting] = useState(false);
  const handleSettingOpen = () => setOpenSetting(true);
  const handleSettingClose = () => setOpenSetting(false);
  const [openResetPass, setOpenResetPass] = useState(false);
  const handleResetPassOpen = () => setOpenResetPass(true);
  const handleResetPassClose = () => setOpenResetPass(false);

  return (
    <>
      {/* Setting Modal */}
      <Modal className="setting_modal"
        open={openSetting}
        onClose={handleSettingClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalStyle}>
          <Typography sx={{ mb: 2 }} id="modal-modal-title" variant="h6" component="h2">
            Setting
          </Typography>
          <TextField sx={{ mb: 2 }} id="outlined-basic" label="Partner Name" variant="outlined" fullWidth value={partnerName} onChange={e => setPartnerName(e.target.value)}/>
          <TextField sx={{ mb: 2 }} id="outlined-basic" label="Currency For Reports" variant="outlined" fullWidth value={currencyForReports} onChange={e => setCurrencyForReports(e.target.value)}/>
          <TextField sx={{ mb: 2 }} id="outlined-basic" label="Notification Subs" variant="outlined" fullWidth value={notificationSubs} onChange={e => setNotificationSubs(e.target.value)}/>
          <TextField sx={{ mb: 2 }} id="outlined-basic" label="Time Zone" variant="outlined" fullWidth value={timezone} onChange={e => setTimezone(e.target.value)}/>
          <div className="btns" style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button variant="contained" color="primary" onClick={handleSettingClose}>Reject</Button>
            <Button sx={{ ml: 1 }} variant="contained" color="secondary" onClick={handleSettingSave}>Save</Button>
          </div>
        </Box>
      </Modal>
      {/* End Setting Modal */}

      {/* Reset Password Modal */}
      <Modal className="reset_password"
        open={openResetPass}
        onClose={handleResetPassClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalStyle}>
          <Typography sx={{ mb: 2 }} id="modal-modal-title" variant="h6" component="h2">
            Reset Password
          </Typography>
          <TextField sx={{ mb: 2 }} id="outlined-basic" label="Current Password" variant="outlined" fullWidth value={curPassword} onChange={e => setCurPassword(e.target.value)}/>
          <TextField sx={{ mb: 2 }} id="outlined-basic" label="New Password" variant="outlined" fullWidth value={newPassword} onChange={e => setNewPassword(e.target.value)}/>
          <TextField sx={{ mb: 2 }} id="outlined-basic" label="Confirm Password" variant="outlined" fullWidth value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)}/>
          <div className="btns" style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button variant="contained" color="primary" onClick={handleResetPassClose}>Cancel</Button>
            <Button sx={{ ml: 1 }} variant="contained" color="secondary" onClick={handleResetPassSave}>Save</Button>
          </div>
        </Box>
      </Modal>
      {/* End Reset Password Modal */}

      <Button
        className="min-h-40 min-w-40 px-0 md:px-16 py-0 md:py-6"
        onClick={userMenuClick}
        color="inherit"
      >
        <div className="hidden md:flex flex-col mx-4 items-end">
          <Typography component="span" className="font-semibold flex">
            {user?.data?.displayName}
          </Typography>
          <Typography className="text-11 font-medium capitalize" color="text.secondary">
            {user.role?.toString()}
            {(!user.role || (Array.isArray(user.role) && user.role.length === 0)) && 'Guest'}
          </Typography>
        </div>

        {AuthRoles.affiliate.includes(user.role) ?
          <>
            <Avatar className="md:mx-4"> <img src={avatarImg} alt="" /></Avatar>
          </>
          :
          AuthRoles.admin.includes(user.role) ?
            <>
              {user.data.photoURL ? (
                // <Avatar className="md:mx-4" alt="user photo" src={user.data.photoURL} />
                <Avatar className="md:mx-4" alt="user photo"> <img src={avatarImg} alt="" /></Avatar>
              ) : (
                <Avatar className="md:mx-4"> <img src={avatarImg} alt="" /></Avatar>
              )}
            </>
            : AuthRoles.betshop.includes(user.role) ?
            <>
            <Avatar className="md:mx-4"> <img src={avatarImg} alt="" /></Avatar>
          </>
            :
            <>
              {user.data?.photoURL ? (
                // <Avatar className="md:mx-4" alt="user photo" src={user.data.photoURL} />
                <Avatar className="md:mx-4" alt="user photo"> <img src={avatarImg} alt="" /></Avatar>

              ) : (
                <Avatar className="md:mx-4"> <img src={avatarImg} alt="" /></Avatar>
              )}
            </>
            }

      </Button>

      <Popover
        open={Boolean(userMenu)}
        anchorEl={userMenu}
        onClose={userMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        classes={{
          paper: 'py-8',
        }}
      >
        {!user.role || user.role.length === 0 ? (
          <>
            <MenuItem component={Link} to="/sign-in" role="button">
              <ListItemIcon className="min-w-40">
                <FuseSvgIcon>heroicons-outline:lock-closed</FuseSvgIcon>
              </ListItemIcon>
              <ListItemText primary="Sign In" />
            </MenuItem>
            <MenuItem component={Link} to="/sign-up" role="button">
              <ListItemIcon className="min-w-40">
                <FuseSvgIcon>heroicons-outline:user-add </FuseSvgIcon>
              </ListItemIcon>
              <ListItemText primary="Sign up" />
            </MenuItem>
          </>
        ) : (
          <>
            {AuthRoles.affiliate.includes(user.role) ?
              <>
                <MenuItem
                  // onClick={userMenuClose} 
                  onClick={handleResetPassOpen}
                  role="button">
                  <ListItemIcon className="min-w-40">
                    <FuseSvgIcon>heroicons-outline:mail-open</FuseSvgIcon>
                  </ListItemIcon>
                  <ListItemText primary="Reset Password" />
                </MenuItem>
                <MenuItem
                  component={NavLink}
                  to="/sign-out"
                  onClick={() => {
                    userMenuClose();
                  }}
                >
                  <ListItemIcon className="min-w-40">
                    <FuseSvgIcon>heroicons-outline:logout</FuseSvgIcon>
                  </ListItemIcon>
                  <ListItemText primary="Sign out" />
                </MenuItem>
              </>
              : AuthRoles.operator.includes(user.role) ?
              <>
                <MenuItem
                  // onClick={userMenuClose} 
                  onClick={handleResetPassOpen}
                  role="button">
                  <ListItemIcon className="min-w-40">
                    <FuseSvgIcon>heroicons-outline:mail-open</FuseSvgIcon>
                  </ListItemIcon>
                  <ListItemText primary="Reset Password" />
                </MenuItem>
                <MenuItem
                  component={NavLink}
                  to="/sign-out"
                  onClick={() => {
                    userMenuClose();
                  }}
                >
                  <ListItemIcon className="min-w-40">
                    <FuseSvgIcon>heroicons-outline:logout</FuseSvgIcon>
                  </ListItemIcon>
                  <ListItemText primary="Sign out" />
                </MenuItem>
              </>
             : AuthRoles.betshop.includes(user.role) ?
              <>
                <MenuItem
                  // onClick={userMenuClose}
                  onClick={handleSettingOpen}
                  role="button">
                  <ListItemIcon className="min-w-40">
                    <FuseSvgIcon>heroicons-outline:user-circle</FuseSvgIcon>
                  </ListItemIcon>
                  <ListItemText primary="Settings" />
                </MenuItem>
                <MenuItem
                  // onClick={userMenuClose} 
                  onClick={handleResetPassOpen}
                  role="button">
                  <ListItemIcon className="min-w-40">
                    <FuseSvgIcon>heroicons-outline:mail-open</FuseSvgIcon>
                  </ListItemIcon>
                  <ListItemText primary="Reset Password" />
                </MenuItem>
                <MenuItem
                  component={NavLink}
                  to="/sign-out"
                  onClick={() => {
                    userMenuClose();
                  }}
                >
                  <ListItemIcon className="min-w-40">
                    <FuseSvgIcon>heroicons-outline:logout</FuseSvgIcon>
                  </ListItemIcon>
                  <ListItemText primary="Sign out" />
                </MenuItem>
              </>
            : AuthRoles.admin.includes(user.role) || AuthRoles.account_user.includes(user.role) ?
              <>
                {/* <MenuItem component={Link} to="/apps/profile" onClick={userMenuClose} role="button">
                  <ListItemIcon className="min-w-40">
                    <FuseSvgIcon>heroicons-outline:user-circle</FuseSvgIcon>
                  </ListItemIcon>
                  <ListItemText primary="My Profile" />
                </MenuItem>
                <MenuItem component={Link} to="/apps/mailbox" onClick={userMenuClose} role="button">
                  <ListItemIcon className="min-w-40">
                    <FuseSvgIcon>heroicons-outline:mail-open</FuseSvgIcon>
                  </ListItemIcon>
                  <ListItemText primary="Inbox" />
                </MenuItem> */}
                <MenuItem
                  // onClick={userMenuClose} 
                  onClick={handleResetPassOpen}
                  role="button">
                  <ListItemIcon className="min-w-40">
                    <FuseSvgIcon>heroicons-outline:mail-open</FuseSvgIcon>
                  </ListItemIcon>
                  <ListItemText primary="Reset Password" />
                </MenuItem>
                <MenuItem component={NavLink} to="/sign-out" onClick={() => { userMenuClose(); }}>
                  <ListItemIcon className="min-w-40">
                    <FuseSvgIcon>heroicons-outline:logout</FuseSvgIcon>
                  </ListItemIcon>
                  <ListItemText primary="Sign out" />
                </MenuItem>
              </>
           : 
           <>
           <MenuItem component={Link} to="/apps/profile" onClick={userMenuClose} role="button">
             <ListItemIcon className="min-w-40">
               <FuseSvgIcon>heroicons-outline:user-circle</FuseSvgIcon>
             </ListItemIcon>
             <ListItemText primary="My Profile" />
           </MenuItem>
           <MenuItem component={Link} to="/apps/mailbox" onClick={userMenuClose} role="button">
             <ListItemIcon className="min-w-40">
               <FuseSvgIcon>heroicons-outline:mail-open</FuseSvgIcon>
             </ListItemIcon>
             <ListItemText primary="Inbox" />
           </MenuItem>
           <MenuItem component={NavLink} to="/sign-out" onClick={() => { userMenuClose(); }}>
             <ListItemIcon className="min-w-40">
               <FuseSvgIcon>heroicons-outline:logout</FuseSvgIcon>
             </ListItemIcon>
             <ListItemText primary="Sign out" />
           </MenuItem>
         </>
           }
          </>
        )}
      </Popover>
    </>
  );
}

export default UserMenu;
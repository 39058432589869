import ResultTable from "./ResultTable";
import { FilterHeader } from "./FilterHeader";
import { useEffect, useState } from "react";
import { headRowsData } from "./tableData";
import { Box } from "@mui/material";
import { useDispatch } from "react-redux";
import { getAllResultManagementData, getResultManagementData, selectAllResults } from "../store/resultManagementSlice";
import { YMD } from "src/app/utils/string";
import { selectAllCountries } from "../store/countriesSlice";
import { useSelector } from "react-redux";


const filterInitialValue = {
  startDate: "",
  endDate: "",
  betType: "",
  sport: "",
  country: "",
  league: "",
  status: "",
}

export const ResultManagement = () => {
  // const [results, setResults] = useState([]);
  const dispatch = useDispatch();
  const [filterAll, setFilterAll] = useState(filterInitialValue);
  const { startDate, endDate } = filterAll;

  const setFilter = (d) => setFilterAll(data => ({ ...data, ...d }));
  const results = useSelector(selectAllResults);

  useEffect(() => {
    dispatch(getAllResultManagementData());
  }, [])

  const handleGet = () => {
    dispatch(getResultManagementData(
      {...filterAll, startDate: YMD(startDate), endDate: YMD(endDate)}
    ));
  }

  return (
    <>
      <FilterHeader onFilterChange={handleGet} setFilter={setFilter} filterAll={filterAll} />
      <Box className="mt-[24px]">
        <ResultTable
          headCells={headRowsData()}
          rows={results}
          filter={filterAll}
        />
      </Box>
    </>
  );
};

import i18n from "src/i18n";
import FuseSvgIcon from "@fuse/core/FuseSvgIcon";
import { Box, Button, Typography } from "@mui/material"
import { SelectGroup } from "app/shared-components/SelectGroup"
import { makeStyles } from "@mui/styles";
import CustomDatePicker from "../../../components/date-picker/CustomDatePicker";
import { useDispatch } from "react-redux";
import { getCountries, getLeagues, getSports } from "../store/resultManagementSlice";
import { useSelector } from "react-redux";
import { selectCurrentLanguageId } from "app/store/i18nSlice";
import { useEffect, useState } from "react";

const betTypeOptions = [
  { value: "Prematch", label: "Prematch" },
  { value: "Live", label: "Live" },
]

const resultStatusOptions = [
  { value: "Waiting", label: "Waiting" },
  { value: "Won", label: "Won" },
  { value: "Lost", label: "Lost" },
  { value: "Canceled", label: "Canceled" },
  { value: "Refunded", label: "Refunded" },
  { value: "CashedOut", label: "CashedOut" },
]

export const FilterHeader = ({ onFilterChange, filterAll, setFilter }) => {
  const dispatch = useDispatch();
  const currentLanguageId = useSelector(selectCurrentLanguageId);

  const {
    startDate,
    endDate,
    betType,
    sport,
    country,
    league,
    status,
  } = filterAll;
  const [sportOptions, setSportOptions] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);
  const [leagueOptions, setLeagueOptions] = useState([]);
  const [sportId, setSportId] = useState();

  useEffect(() => {
    dispatch(getSports({langId: currentLanguageId})).then((res) => {
      setSportOptions(
        res.payload.map((sport) => ({ value: sport.id, label: sport.name }))
      )
    })
  }, []) 


  const useStyles = makeStyles((theme) => ({
    getButton: {
      // marginTop: theme.spacing(2),
      marginTop: 10,
    },
  }));

  const onSubmit = () => {
    onFilterChange(/** , , , */);
  }

  const handleSportChange = (sportId) => {
    setFilter({ sport: sportId });
    setSportId(sportId);
    dispatch(getCountries({ langId: currentLanguageId, sportId: sportId })).then((res) => {
      setCountryOptions(
        res.payload.map((country) => ({ value: country.id, label: country.name }))
      )
    })
  }

  const handleCountryChange = (countryId) => {
    setFilter({ country: countryId });
    dispatch(getLeagues({ langId: currentLanguageId, sportId: sportId, countryId: countryId })).then((res) => {
      setLeagueOptions(
        res.payload.map((league) => ({ value: league.id, label: league.name }))
      )
    })
  }
  
  return (
    <Box className="container max-w-[1600px] mx-auto pt-[64px]">
      <Typography className="text-3xl font-semibold">{i18n.t("Result_Management")}</Typography>
      <Box className="flex flex-col lg:flex-row gap-[4px] lg:items-end mt-[24px]">
        <CustomDatePicker
          startDate={startDate}
          endDate={endDate}
          onChange={(dates) => {
            setFilter({startDate: dates[0]});
            setFilter({endDate: dates[1]});
          }}
        />
        <SelectGroup 
          label={i18n.t("Bet_Type")}
          minWidth="240px" 
          options={betTypeOptions} 
          value={betType}
          onChange={(e) => {
            setFilter({ betType: e.value });
          }}
        />
        <SelectGroup 
          label={i18n.t("Sport")}
          minWidth="240px" 
          options={sportOptions}
          value={sport}
          onChange={(e) => handleSportChange(e.value)}
        />
        <SelectGroup 
          label={i18n.t("Country")}
          minWidth="240px"
          options={countryOptions} 
          value={country}
          onChange={(e) => handleCountryChange(e.value)}
        />
        <SelectGroup 
          label={i18n.t("League")}
          minWidth="240px" 
          options={leagueOptions} 
          value={league}
          onChange={(e) => setFilter({ league: e.value })}  
        />
        <SelectGroup 
          label={i18n.t("Result_Status")}
          minWidth="240px" 
          options={resultStatusOptions} 
          value={status}
          onChange={(e) => setFilter({ status: e.value })}
        />
        <Button
          className={useStyles.getButton}
          variant="contained"
          color="secondary"
          startIcon={
            <FuseSvgIcon>heroicons-outline:chevron-right</FuseSvgIcon>
          }
          onClick={onFilterChange}
          sx={{ px: "24px" }}
        >
          {i18n.t("Get")}
        </Button>
      </Box>
    </Box>
  )
}
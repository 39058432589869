import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import jwtServiceConfig from "../../../auth/services/jwtService/jwtServiceConfig";
const api_domain = jwtServiceConfig.apiUrl;

export const getMarkets = createAsyncThunk('sportDataApp/markets/getMarkets', async ({groupType, currentLanguageId, pageNumber, pageLimit}) => {
  const response = await axios.get(`${api_domain}markets/groupId/${groupType}/languageId/${currentLanguageId}?page=${pageNumber}&limit=${pageLimit}`);
  return {
    markets: response.data.markets,
    total_count: response.data.paginator.total_count,
  };
});

export const setMarketActive = createAsyncThunk('sportDataApp/sportEvent/setActive',
  async (id) => {
  const response = await axios.get(`${api_domain}markets/setActive/id/${id}`);
  return response.data.id;
});

export const setMarketInactive = createAsyncThunk('sportDataApp/sportEvent/setInactive',
  async (id) => {
  const response = await axios.get(`${api_domain}markets/setInactive/id/${id}`);
  return response.data.id;
});

export const setCashOutActive = createAsyncThunk('sportDataApp/sportEvent/setCashOutActive',
  async (id) => {
  const response = await axios.get(`${api_domain}markets/setCashOutActive/id/${id}`);
  return response.data.id;
});

export const setCashOutInactive = createAsyncThunk('sportDataApp/sportEvent/setCashOutInactive',
  async (id) => {
  const response = await axios.get(`${api_domain}markets/setCashOutInactive/id/${id}`);
  return response.data.id;
});

const marketsAdapter = createEntityAdapter({});

export const {
  selectById: selectMarketById,
  selectIds: selectMarketIds,
  selectEntities: selectMarketEntities,
  selectAll: selectAllMarkets,
  selectTotal: selectTotalMarkets,
} = marketsAdapter.getSelectors((state) => state.sportDataApp.markets);

const marketsSlice = createSlice({
  name: 'sportDataApp/markets',
  initialState: marketsAdapter.getInitialState({    
    totalCount: 0,
  }),
  reducers: {},
  extraReducers: {
    [getMarkets.fulfilled]: (state, action) => {
      if (!action.payload.markets) {
        marketsAdapter.setAll(state, {})
        state.totalCount = 0;
      } else {
        marketsAdapter.setAll(state, action.payload.markets)
        state.totalCount = action.payload.total_count;
      }

    }
  },
});

export const selectTotalCount = ({ sportDataApp }) =>
  sportDataApp.markets.totalCount;

export default marketsSlice.reducer;
import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

const CasinoVendors = lazy(() => import('./vendors/Vendors'));
const CasinoGames = lazy(() => import('./games/Games'));

const casinoConfigs = [{
  settings: {
    layout: {},
  },
  routes: [
    {
      path: 'settings/casino/vendors',
      element: <CasinoVendors />,
    },
    {
      path: 'settings/casino/games',
      element: <CasinoGames />,
    },
  ],
}];

export default casinoConfigs;

import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import axios from 'axios';
import { showMessage } from 'app/store/fuse/messageSlice';
import jwtServiceConfig from 'src/app/auth/services/jwtService/jwtServiceConfig';
import toast from 'react-hot-toast'
import Alert from '@mui/material/Alert';

const api_domain = jwtServiceConfig.apiUrl;

export const getAffiliate = createAsyncThunk('accountApp/affiliate/getAffiliate', async (affiliateId) => {
  const response = await axios.get(`${api_domain}affiliate/${affiliateId}`);
  const data = await response.data.affiliate;

  return data === undefined ? null : data;
});

export const setVerified = createAsyncThunk(
    'accountApp/affiliate/setVerified',
    // async (token) => {
    async (token, { rejectWithValue, dispatch }) => {
      try {
        const response = await axios.get(`${api_domain}affiliate/setVerified/${token}`);
        const data = await response.data;

        return data === undefined ? null : data;

      } catch (err) {
        console.log(err)
        return rejectWithValue(err.response.data)
      }
  }
);

export const saveAffiliate = createAsyncThunk(
  'accountApp/affiliate/saveAffiliate',
  // `arg` is used to pass data and affiliateId in a single object
  async ({ data, affiliateId = '' }, { rejectWithValue }) => {
    try {
      // Destructure the fields from the data object
      const {
        status,
        active,
        agreement_percentage,
        country,
        email,
        first_name,
        last_name,
        network_cost,
        password,
        currency,
        phone,
        re_password,
        user_name,
      } = data;

      // Make the API request with the correct URL and data payload
      const response = await axios.put(
        `${api_domain}affiliate/save/${affiliateId}`,
        {
          active,
          status,
          agreement_percentage: parseFloat(agreement_percentage),
          country,
          email,
          first_name,
          last_name,
          network_cost: parseFloat(network_cost),
          password,
          payout_currency: currency,
          phone,
          re_password,
          user_name,
        }
      );

      // Display success message using toast
      toast.custom(
        <Alert variant="filled" severity="success">Affiliate Saved</Alert>
      );

      // Return the response data if the request is successful
      return response.data;
    } catch (err) {
      console.error(err); // Log the error for debugging

      // Check if the error has a response with status and user error message
      if (err?.response?.data?.status) {
        // Display error message using toast
        toast.custom(
          <Alert variant="filled" severity="error">{err.response.data.user_error}</Alert>
        );

        // Optionally, reject with a value to handle the error state in the reducer
        return rejectWithValue(err.response.data.user_error);
      }

      // If no specific error handling, throw the error
      throw err;
    }
  }
);

const affiliateSlice = createSlice({
  name: 'accountApp/affiliate',
  initialState: null,
  reducers: {
    resetAffiliate: () => null,
    newAffiliate: {
      reducer: (state, action) => action.payload,
      prepare: (event) => {
        return {
          payload: {
            first_name: '',
            last_name: '',
            user_name: '',
            email: '',
            country: '',
            password: '',
            phone: '',
            payout_currency: '',
            agreement_percentage: '',
            network_cost: '',
            active: true,
          }
        }
      },
    },
  },
  extraReducers: {
    [getAffiliate.fulfilled]: (state, action) => action.payload,
    [saveAffiliate.fulfilled]: (state, action) => action.payload,
    [setVerified.fulfilled]: (state, action) => action.payload,
    /*[saveAffiliate.fulfilled]: (state, action) => {
      state.newId = action.payload.id
    },*/
  },
});

export const { newAffiliate, resetAffiliate } = affiliateSlice.actions;

export const selectAffiliate = ({ accountApp }) => accountApp.affiliate;

export default affiliateSlice.reducer;


import Alert from "@mui/material/Alert";
import {
    createAsyncThunk,
    createEntityAdapter,
    createSlice,
} from "@reduxjs/toolkit";
import axios from "axios";
import toast from "react-hot-toast";
import jwtServiceConfig from "src/app/auth/services/jwtService/jwtServiceConfig";

const api_domain = jwtServiceConfig.apiUrl;

export const getSupplierDetails = createAsyncThunk(
  "betshopAccountSupplierApp/supplierDetails/getSupplierDetails",
  async (userId) => {
    const response = await axios.get(`${api_domain}betshop/supplier/${userId}`);
    const data = await response.data.user;
    return data === undefined ? null : data;
  }
);

export const addBalance = createAsyncThunk(
  "betshopAccountSupplierApp/supplier/addBalance",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.put(
        `${api_domain}betshop/supplier/addBalance`,
        data
      );
      toast.custom(
        <Alert severity="success">Balance has been successully added</Alert>
      );

      return response.data.user;
    } catch (err) {
      toast.custom(
        <Alert severity="error">{err.response.data.user_error}</Alert>
      );
      if (err?.response?.data?.status) {
        return err?.response;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateAdmin = createAsyncThunk(
  "betshopAccountSupplierApp/supplier/saveAdmin",
  async (body) => {
    try {
      let data = {
        role: body.role,
        first_name: body.first_name,
        sur_name: body.sur_name,
        last_name: body.last_name,
        phone: body.phone,
        email: body.email,
        supplier_limit: body.supplier_limit,
        user_limit: body.user_limit,
        currency: body.currency,
        username: body.username,
      };
      const response = await axios.put(
        `${api_domain}betshop/supplier/save/${body.userId}`,
        data
      );
      return response.data.success;
    } catch (err) {
      if (err?.response?.data?.status) {
        return err?.response;
      }

      return rejectWithValue(err.response.data);
    }
  }
);

const supplierDetailsAdapter = createEntityAdapter({});

const supplierDetailsSlice = createSlice({
  name: "betshopAccountSupplierApp/supplierDetails",
  initialState: supplierDetailsAdapter.getInitialState(),
  reducers: {},
  extraReducers: {
    [getSupplierDetails.fulfilled]: (state, action) => {
        console.log("FULLFILLED", action.payload);
        return action.payload},
  },
});

export const selectedSupplier = ({ betshopAccountSupplierApp }) =>
  betshopAccountSupplierApp.supplierDetails;

export default supplierDetailsSlice.reducer;

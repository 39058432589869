import { lazy } from 'react';
import authRoles from "../../auth/authRoles";

// const Affiliates = lazy(() => import('./affiliates/index'));
const PagesOfContents = lazy(() => import('./PagesOfContents/PagesOfContents'));
const Promotions = lazy(() => import('./Promotions/Promotions'));
const Banners = lazy(() => import('./Banners/Banners'));
const Landingpage = lazy(() => import('./Landingpage/Landingpage'));
const PageOfContentsDetail = lazy(() => import('./PagesOfContents/Detail/Detail'));
const PromotionsDetail = lazy(() => import('./Promotions/Detail/Detail'));
const BannersDetail = lazy(() => import('./Banners/Detail/Detail'));
const LandingpageDetail = lazy(() => import('./Landingpage/Detail/Detail'));

const cmsConfigs = [{
  settings: {
    layout: {},
  },
//  auth: authRoles.operator,
  routes: [
    {
      path: 'cms/pages_of_contents',
      element: <PagesOfContents />,
    },
    {
      path: 'cms/pages_of_contents/detail/:cmsId',
      element: <PageOfContentsDetail />,
    },
    {
      path: 'cms/promotions',
      element: <Promotions />,
    },
    {
      path: 'cms/promotions/detail/:cmsId',
      element: <PromotionsDetail />,
    },
    {
      path: 'cms/banners',
      element: <Banners />,
    },
    {
      path: 'cms/banners/detail/:cmsId',
      element: <BannersDetail />,
    },
    {
      path: 'cms/landingpage',
      element: <Landingpage />,
    },
    {
      path: 'cms/landingpage/detail/:cmsId',
      element: <LandingpageDetail />,
    },
  ],
}];

export default cmsConfigs;
import ResultTable from "./ResultTable"
import { FilterHeader } from "./FilterHeader"
import { useState } from "react";
import { headRowsData } from "./tableData";
import { Box } from "@mui/material";

const resultsData = [
  {
    event_id: "9891258",
    start_date: Date(),
    event_name: "Turan Tovuz-Kapaz",
    sport_type: "Football",
    country: "Azerbayjan",
    league_tournament: "Premier League",
    active: true
  },
  {
    event_id: "9891258",
    start_date: Date(),
    event_name: "Turan Tovuz-Kapaz",
    sport_type: "Football",
    country: "Azerbayjan",
    league_tournament: "Premier League",
    active: true
  },
  {
    event_id: "9891258",
    start_date: Date(),
    event_name: "Turan Tovuz-Kapaz",
    sport_type: "Football",
    country: "Azerbayjan",
    league_tournament: "Premier League",
    active: true
  },
]

export const ResultManagement = () => {
  const [results, setResults] = useState(resultsData);
  const [filter, setFilterT] = useState({
    Betslip_Id: "",
    admins: "",
    suppliers: "",
    Username: "",
    Currency: "all",
    Coupon_type: "all",
    Game_type: "all",
    Status: "all",
  });
  
  const handleFilterChange = () => {

  }
  
  return (
    <>
      <FilterHeader onFilterChange={handleFilterChange} />
      <Box className="mt-[24px]">
        <ResultTable headCells={headRowsData()} rows={results} filter={filter} />
      </Box>
    </>
  )
}
import { lazy } from 'react';
import authRoles from "../../auth/authRoles";

// const Affiliates = lazy(() => import('./affiliates/index'));
const OffersList = lazy(() => import('./offers_list/OffersList'));
const BonusList = lazy(() => import('./bonus_list/BonusList'));
const BonusConfig = lazy(() => import('./bonus_config/BonusConfig'));
const AddBonus = lazy(() => import('./bonus_list/add_bonus/AddBonus'));
const BonusDetail = lazy(() => import('./bonus_list/bonus_detail/BonusDetail'));

const bonusesConfigs = [{
  settings: {
    layout: {},
  },
  // auth: authRoles.manager,
  routes: [
    {
      path: 'bonuses/offers_list',
      element: <OffersList />,
    },
    {
        path: 'bonuses/bonus_list',
        element: <BonusList />,
      },
      {
        path: 'bonuses/bonus_config',
        element: <BonusConfig />,
      },
      {
        path: 'bonuses/add_bonus',
        element: <AddBonus />,
      },
      {
        path: 'bonuses/detail/:bonusId',
        element: <BonusDetail />,
      },
  ],
}];

export default bonusesConfigs;
import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { authRoles } from "src/app/auth";
import jwtServiceConfig from "src/app/auth/services/jwtService/jwtServiceConfig";

const api_domain = jwtServiceConfig.apiUrl;

export const getBetslipReports = createAsyncThunk(
  "betshopAdmin/getBetslipReports",
  async ({
    user,
    filter
  }) => {
    const { startDate, endDate, admin, supplier, user: username, couponType: coupon, gameType: game } = filter;
    if (authRoles.online.includes(user.role)) {
      const { data } = await axios.get(`${api_domain}online/report/betslip/${startDate}/${endDate}/${username}/${coupon}/${game}`);
      return data.betslip_report || [];
    } else {
      // betshop
      try {
        let res = [];
        const couponType = coupon === "all" ? "all" : coupon;
        const gameType = game === "all" ? "all" : game;
  
        if (admin === "all") {
          res = await axios.get(`${api_domain}betshop/report/betslip/${startDate}/${endDate}/all/${couponType}/${gameType}`);
        } else if (supplier === "all") {
          res = await axios.get(`${api_domain}betshop/report/betslip/${startDate}/${endDate}/admin/${admin}/all/${couponType}/${gameType}`);
        } else if (username === "") {
          res = await axios.get(`${api_domain}betshop/report/betslip/${startDate}/${endDate}/supplier/${supplier}/all/${couponType}/${gameType}`);
        } else {
          res = await axios.get(`${api_domain}betshop/report/betslip/${startDate}/${endDate}/user/${username}/all/${couponType}/${gameType}`);
        }
        return res.data.betslip_report || [];
      } catch (_) {
        // 
        return [];
      }
    }
  }
)

const reportAdapter = createEntityAdapter();

export const { selectAll: selectBetslipReports } = reportAdapter.getSelectors(state => state.betshopAdmin.betslipReports);

const reportSlice = createSlice({
  name: "betshopAdmin/betslipReports",
  initialState: reportAdapter.getInitialState(),
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getBetslipReports.fulfilled, (state, action) => {
      reportAdapter.setAll(state, action.payload);
    })
  }
})

export default reportSlice.reducer;

import { DateRangePicker, Stack } from 'rsuite';
import subDays from 'date-fns/subDays';
import startOfWeek from 'date-fns/startOfWeek';
import endOfWeek from 'date-fns/endOfWeek';
import addDays from 'date-fns/addDays';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import addMonths from 'date-fns/addMonths';
import {format} from "date-fns";
import { useEffect } from 'react';



const predefinedBottomRanges = [
  {
    label: 'Today',
    value: [new Date(), new Date()]
  },
  {
    label: 'Yesterday',
    value: [addDays(new Date(), -1), addDays(new Date(), -1)]
  },
  {
    label: 'This week',
    value: [startOfWeek(new Date()), endOfWeek(new Date())]
  },
  {
    label: 'This month',
    value: [startOfMonth(new Date()), new Date()]
  },
  {
    label: 'Last month',
    value: [startOfMonth(addMonths(new Date(), -1)), endOfMonth(addMonths(new Date(), -1))]
  }
];


export default function BasicRangeShortcuts(props) {

  const createChangeHandler = (dates) => {
    if (props?.onChange) {
      props.onChange(dates);
    }
  };

  return (
    <DateRangePicker
      placement={props.placement || 'bottomStart'}
      className={props.className}
      ranges={predefinedBottomRanges}
      value={[props.startDate, props.endDate]}
      placeholder={props?.placeholder ? props.placeholder : "Select Custom Date:"}
      // style={{ width: 260 }}
      onChange={createChangeHandler}
      onShortcutClick={(shortcut, event) => {
        console.log(shortcut);
      }}
    />
  );
}
import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { authRoles } from 'src/app/auth';

const Deposit = lazy(() => import('./Deposit/Deposit'));
const Withdraw = lazy(() => import('./Withdraw/Withdraw'));
const Transaction = lazy(() => import('./Transaction/Transaction'));

const onlineFinanceConfigs = [
  {
    settings: {
      layout: {},
    },
    auth: authRoles.online,
    routes: [
      {
        path: 'finance/deposit',
        element: <Deposit />,
      },
      {
        path: 'finance/withdraw',
        element: <Withdraw />,
      },
    ],
  },
  {
    settings: {
      layout: {},
    },
    auth: authRoles.betshop_online,
    routes: [
      {
        path: 'finance/transaction',
        element: <Transaction />,
      }
    ]
  }
];

export default onlineFinanceConfigs;

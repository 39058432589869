import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import {Link, useNavigate, useParams} from 'react-router-dom';
import * as yup from 'yup';
import _ from '@lodash';
import Paper from '@mui/material/Paper';
import FormHelperText from '@mui/material/FormHelperText';
import { useDispatch } from 'react-redux';
import {useEffect, useState} from "react";
import {saveAffiliate, setVerified} from "../accounts/store/affiliateSlice";

function AffiliateVerificationPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const routeParams = useParams();
  const [error, setError] = useState("");

  useEffect(() => {
    const { token } = routeParams;

    dispatch(setVerified(token)).then((response) => {
      // const { userId } = routeParams;
      // console.log(resp)
      if (response?.payload?.user_error) {
        setError(response.payload.user_error)
      }

    }).catch((err) => {
      console.log(err)
    })

  }, []);

  return (
      <div className="flex flex-col flex-auto items-center sm:justify-center min-w-0">
        <Paper className="flex items-center w-full sm:w-auto min-h-full sm:min-h-auto rounded-0 py-32 px-16 sm:p-48 sm:rounded-2xl sm:shadow">
          <div className="w-full max-w-320 sm:w-320 mx-auto sm:mx-0">
            <img className="w-48 mx-auto" src="assets/images/logo/logo.png" alt="logo" />

            <Typography className="mt-32 text-4xl font-extrabold tracking-tight leading-tight text-center">
              {error ? error : "You have verified!"}
            </Typography>
            {/*<Typography className="flex justify-center mt-2 font-medium">
              Redirecting in 5 seconds
            </Typography>*/}

            <Typography className="mt-32 text-md font-medium text-center" color="text.secondary">
              <span>Go to</span>
              <Link className="ml-4" to="/sign-in">
                sign in
              </Link>
            </Typography>
          </div>
        </Paper>
      </div>
  );
}

export default AffiliateVerificationPage;
import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import axios, { getAdapter } from "axios";
import jwtServiceConfig from "src/app/auth/services/jwtService/jwtServiceConfig";
import toast from "react-hot-toast";
import Alert from "@mui/material/Alert";

const api_domain = jwtServiceConfig.apiUrl;

export const getSystems = createAsyncThunk(
  "financeApp/system/getSystems",
  async () => {
    // console.log('RESPONSE', response);
    try {
      const response = await axios.get(`${api_domain}meganet/finance/systems`);
      const data = await response.data.system_info;
      return data;
    } catch (error) {
      toast.custom(
          <Alert variant="filled" severity="success">{err.response.data.user_error}</Alert>
      );
    }
  }
);

export const updateSystem = createAsyncThunk(
  "financeApp/system/updateSystem",
  async (body) => {
    try {
      const response = await axios.put(
        `${api_domain}meganet/finance/addOperatorBalance`,
        body
      );
      const data = await response.data;
      if (body.transaction_type == "Deposit") {
        toast.custom(
          <Alert variant="filled" severity="success">Balance has been successfully added</Alert>
        );
      } else {
        toast.custom(
          <Alert variant="filled" severity="success">
            Balance has been successfully withdrawn
          </Alert>
        );
      }
      return data;
    } catch (err) {
      toast.custom(
        <Alert variant="filled" severity="error">{err.response.data.user_error}</Alert>
      );
    }
  }
);

const systemAdapter = createEntityAdapter({
  selectId: (entity) => entity.system.id,
});

export const { selectAll: selectAllSystems } = systemAdapter.getSelectors(
  (state) => state.financeApp.system
);

const systemSlice = createSlice({
  name: "financeApp/system",
  initialState: systemAdapter.getInitialState(),
  reducers: {
    setAll: systemAdapter.setAll,
  },
  extraReducers: {
    [getSystems.fulfilled]: (state, action) => {
      if (!action.payload) {
        systemAdapter.setAll(state, []);
      } else {
        systemAdapter.setAll(state, action.payload);
      }
    },
    [updateSystem.fulfilled]: (state, action) => action.payload,
  },
});

export default systemSlice.reducer;

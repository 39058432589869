import i18next from "i18next";

// import ar from './navigation-i18n/ar';
// import en from './navigation-i18n/en';
// import tr from './navigation-i18n/tr';
import authRoles from "../auth/authRoles";
import permissionRoles from "../auth/permissionRoles";

// i18next.addResourceBundle('en', 'navigation', en);
// i18next.addResourceBundle('tr', 'navigation', tr);
// i18next.addResourceBundle('ar', 'navigation', ar);

const navigationConfig = [
  {
    id: "dashboards_manager",
    title: "Dashboards",
    subtitle: "Unique dashboard designs",
    type: "item",
    icon: "heroicons-outline:home",
    translate: "Dashboards",
    // auth: authRoles.manager,
    //customPermission: 'dashboards_page',
    //customPermissionRoles: permissionRoles.staff,
    url: "/dashboards",
    children: [],
  },
  // {
  //   id: 'dashboards_staff',
  //   title: 'Dashboards',
  //   subtitle: 'Unique dashboard designs',
  //   type: 'item',
  //   icon: 'heroicons-outline:home',
  //   translate: 'Dashboards',
  //   // auth: authRoles.staff,
  //   customPermission: 'translation_and_order_pages_view_and_edit',
  //   customPermissionRoles: permissionRoles.staff,
  //   url: '',
  //   children: [],
  // },
  {
    id: "dashboards_betshop_admin",
    title: "Dashboards",
    subtitle: "Unique dashboard designs",
    type: "item",
    icon: "heroicons-outline:home",
    translate: "Dashboards",
    //customPermission: 'dashboards_page',
    //customPermissionRoles: permissionRoles.staff,
    auth: authRoles.betshop_admin,
    url: "/betshop_admin/dashboard",
    children: [],
  },
  {
    id: "sportData",
    title: "Sport data",
    subtitle: "Sport Data",
    type: "group",

    icon: "material-solid:sports_handball",
    auth: authRoles.manager,
    translate: "Sport_Data",
    children: [
      {
        id: "dashboards.sport-event",
        title: "Sport Events",
        translate: "Sport_Events",
        type: "item",
        icon: "material-outline:emoji_events",
        url: "/sport-data/events",
      },
      {
        id: "dashboards.betting-settings",
        title: "Betting Setting/Bet Markets",
        translate: "Betting_Setting",
        type: "item",
        icon: "feather:settings",
        url: "/sport-data/betting_setting",
      },
      {
        id: "dashboards.favourite-league",
        title: "Favourite League",
        translate: "Favorite_League",
        type: "item",
        icon: "feather:settings",
        url: "/sport-data/favourite_league",
      },
      {
        id: "dashboard.result-management",
        title: "Result Management",
        translate: "Result_Management",
        type: "item",
        icon: "feather:settings",
        url: "/sport-data/result-management",
        auth: authRoles.admin,
      }
    ],
  },
  {
    id: "management.system",
    title: "Systems/Operators",
    subtitle: "Unique Systems/Operators",
    type: "item",
    icon: "material-solid:desktop_mac",
    auth: authRoles.admin,
    translate: "Systems_Operators",
    url: "/management/systems",
    children: [],
  },
  // {
  //   id: "casino",
  //   title: "Casino Vendors and Providers",
  //   subtitle: "Casino Vendors and Providers",
  //   type: "item",
  //   icon: "feather:user",
  //   translate: "Casino_Vendors_and_Providers",
  //   auth: authRoles.admin,
  //   url: "/casino/vendors_and_providers",
  //   children: [],
  // },
  {
    id: "finance",
    title: "Finance",
    subtitle: "Unique Finance",
    type: "group",
    icon: "feather:dollar-sign",
    auth: authRoles.finance,
    translate: "Finance",
    //url: '/management/finance',
    children: [
      {
        id: "finance.system",
        title: "System",
        translate: "System",
        type: "item",
        icon: "feather:dollar-sign",
        auth: authRoles.admin,
        url: "/finance/system",
      },
      {
        id: "finance.system_financial",
        title: "System Financial",
        translate: "System_Financial",
        type: "item",
        icon: "feather:dollar-sign",
        auth: authRoles.admin,
        url: "/finance/system_financial",
      },
      {
        id: "finance.transactions_logs",
        title: "Transactions Logs",
        translate: "Transactions_Logs",
        type: "item",
        icon: "feather:dollar-sign",
        auth: authRoles.admin,
        url: "/finance/transactions_logs",
      },
      {
        id: "finance.balance_details",
        title: "Balance Deposit/Withdraw",
        translate: "Balance_Deposit_Withdraw",
        type: "item",
        icon: "feather:settings",
        auth: authRoles.account_user,
        url: "/finance/balance_details/sm/balance",
      },
      // {
      //   id: "finance.financial_report",
      //   title: "Financial Report",
      //   translate: "Financial_Report",
      //   type: "item",
      //   icon: "feather:settings",
      //   // auth: authRoles.betshop_online,
      //   auth: authRoles.betshop,
      //   url: "/finance/financial_report",
      // },
      // {
      //   id: "finance.transaction_logs",
      //   title: "Transaction Logs",
      //   translate: "Transaction_Logs",
      //   type: "item",
      //   icon: "feather:settings",
      //   auth: authRoles.account_user,
      //   url: "/finance/transaction_logs",
      // },
      {
        id: "finance.deposit",
        title: "Deposit",
        translate: "Deposit",
        type: "item",
        icon: "material-solid:account_balance_wallet",
        auth: authRoles.online,
        url: "/finance/deposit",
      },
      {
        id: "finance.withdraw",
        title: "Withdraw",
        translate: "Withdraw",
        type: "item",
        icon: "heroicons-outline:document-text",
        auth: authRoles.online,
        url: "/finance/withdraw",
      },
      {
        id: "finance.transaction",
        title: "Transaction",
        translate: "Transaction",
        type: "item",
        icon: "feather:dollar-sign",
        auth: authRoles.betshop_online,
        url: "/finance/transaction",
      },
    ],
  },
  {
    id: "bonuses",
    title: "Bonuses",
    subtitle: "Bonuses",
    type: "group",
    icon: "material-solid:card_giftcard",
    auth: authRoles.online,
    translate: "Bonuses",
    children: [
      {
        id: "bonuses.offers_list",
        title: "List Offers For Users",
        translate: "List_Offers_For_Users",
        type: "item",
        icon: "heroicons-outline:document-text",
        url: "/bonuses/offers_list",
      },
      {
        id: "bonuses.bonus_list",
        title: "Bonus List",
        translate: "Bonus_List",
        type: "item",
        icon: "heroicons-outline:document-text",
        url: "/bonuses/bonus_list",
      },
      // {
      //   id: "bonuses.bonus_config",
      //   title: "Bonus Config",
      //   icon: "material-solid:card_giftcard",
      //   type: "item",
      //   url: "/bonuses/bonus_config",
      // },
    ],
  },
  {
    id: "management.coupon",
    title: "Coupon",
    subtitle: "Unique Coupon",
    type: "item",
    icon: "heroicons-outline:document-text",
    translate: "Coupon",
    auth: authRoles.coupon,
    url: "/management/coupon",
    children: [],
  },
  {
    id: "management.department_users",
    title: "Department users",
    subtitle: "Unique Department users",
    type: "item",
    icon: "feather:users",
    translate: "Staff",
    auth: authRoles.staff_menu,
    url: "/management/department-users",
    children: [],
  },
  /*{
    id: 'casino',
    title: 'Casino',
    subtitle: 'Casino',
    type: 'group',
    icon: 'heroicons-outline:gift',
    translate: 'CASINO',
    children: [
      {
        id: 'casino.vendors',
        title: 'Vendors',
        type: 'item',
        icon: 'feather:user',
        url: '/casino/vendors',
      },
      {
        id: 'casino.games',
        title: 'Games',
        type: 'item',
        icon: 'feather:globe',
        url: '/casino/games',
      },
    ],
  },*/
  {
    id: "accounts",
    title: "Accounts",
    subtitle: "Accounts",
    type: "group",
    icon: "material-solid:account_circle",
    auth: authRoles.accounts,
    translate: "Accounts",
    children: [
      {
        id: "accounts.online_players",
        title: "Online Players",
        type: "item",
        translate: "Online_Players",
        icon: "feather:user",
        auth: authRoles.online,
        url: "/accounts/online_players",
      },
      {
        id: "accounts.affiliates",
        title: "Affiliate",
        type: "item",
        translate: "Affiliate",
        icon: "feather:user",
        auth: authRoles.online,
        url: "/accounts/affiliates",
      },
      {
        id: "accounts.admin",
        title: "Admin",
        translate: "Admin",
        type: "item",
        icon: "feather:user",
        auth: authRoles.betshop,
        url: "/accounts/admin",
      },
      {
        id: "accounts.suppliers",
        title: "Suppliers",
        translate: "Suppliers",
        type: "item",
        icon: "feather:users",
        auth: authRoles.betshop_account,
        url: "/accounts/suppliers",
      },
      {
        id: "accounts.users",
        title: "Users",
        translate: "Users",
        type: "item",
        icon: "feather:users",
        auth: authRoles.betshop_account,
        url: "/accounts/users",
      },
      //     {
      //   id: 'accounts.online_players',
      //   title: 'Online Players',
      //   type: 'item',
      //   translate: 'Online_Players',
      //   icon: 'feather:user',
      //   auth: authRoles.online,
      //   url: '/accounts/online_players',
      // },
    ],
  },
  // {
  //   id: 'coupon_management',
  //   title: 'Coupon Management',
  //   translate: 'COUPON_MANAGEMENT',
  //   type: 'item',
  //   auth: authRoles.betshop_admin,
  //   icon: 'feather:globe',
  //   url: '/betshop_admin/coupon_management',
  // },
  // {
  //   id: 'finance_management',
  //   title: 'Finance Management',
  //   translate: 'FINANCE_AMANGEMENT',
  //   type: 'item',
  //   auth: authRoles.betshop_admin,
  //   icon: 'feather:globe',
  //   url: '/betshop_admin/fianance_management',
  // },
  {
    id: "reports_management",
    title: "Reports",
    subtitle: "Reports",
    type: "group",
    icon: "heroicons-outline:document-text",
    translate: "Reports",
    auth: authRoles.reports,
    children: [
      {
        id: "reports_management.financial_reports",
        title: "Financial",
        type: "item",
        translate: "Financial",
        icon: "feather:dollar-sign",
        url: "/betshop_admin/financial_reports",
      },
      {
        id: "cms.sport_bet_slip_reports",
        title: "BetSlip Reports",
        type: "item",
        translate: "Betslip_Reports",
        icon: "heroicons-outline:document-text",
        url: "/betshop_admin/sport_bet_slip_reports",
      },
      {
        id: "cms.casino_reports",
        title: "Casino Reports",
        type: "item",
        translate: "Casino_Reports",
        icon: "material-solid:casino",
        url: "/betshop_admin/casino_reports",
      },
    ],
  },
  {
    id: "cms",
    title: "CMS",
    subtitle: "CMS",
    type: "group",
    icon: "feather:settings",
    translate: "CMS",
    // auth: authRoles.operator,
    auth: authRoles.cms,
    children: [
      {
        id: "cms.pages_of_contents",
        title: "Pages of Contents",
        type: "item",
        translate: "Pages_Of_Contents",
        icon: "heroicons-outline:document-text",
        url: "/cms/pages_of_contents",
      },
      {
        id: "cms.promotions",
        title: "Promotions",
        type: "item",
        translate: "Promotions",
        auth: authRoles.online,
        icon: "material-twotone:surround_sound",
        url: "/cms/promotions",
      },
      {
        id: "cms.banners",
        title: "Banners",
        type: "item",
        translate: "Banners",
        icon: "heroicons-outline:bookmark",
        url: "/cms/banners",
      },
      // {
      //   id: "cms.landingpage",
      //   title: "Landingpage",
      //   type: "item",
      //   translate: "Landingpage",
      //   icon: "feather:book-open",
      //   url: "/cms/landingpage",
      // },
      // {
      //   id: 'cms.social_media',
      //   title: 'Social Media',
      //   type: 'item',
      //   translate: 'SOCIAL_MEDIA',
      //   icon: 'feather:book-open',
      //   //url: '/cms/market_dictionary',
      // },
      // {
      //   id: 'cms.domains_logo_mail_settings',
      //   title: 'Domains-Logo-Mail Setting',
      //   type: 'item',
      //   translate: 'DOMAINS_LOGO_MAIL_SETTING',
      //   icon: 'feather:book-open',
      //   //url: '/cms/market_dictionary',
      // },
      // {
      //   id: 'cms.Clientside_highlight_leagues',
      //   title: 'Clientside Highlight Leagues',
      //   type: 'item',
      //   translate: 'CLIENTSIDE_HIGHLIGHT_LEAGUES',
      //   icon: 'feather:book-open',
      //  //url: '/cms/market_dictionary',
      // },
      // {
      //   id: 'cms.market_dictionary',
      //   title: 'Clientside Highlight Event',
      //   type: 'item',
      //   translate: 'CLIENTSIDE_HIGHLIGHT_EVENT',
      //   icon: 'feather:book-open',
      //   //url: '/cms/market_dictionary',
      // },
    ],
  },
  {
    id: "accounts",
    title: "Accounts",
    subtitle: "Accounts",
    type: "group",
    icon: "material-solid:account_circle",
    auth: authRoles.online,
    translate: "Accounts",
    children: [
      {
        id: "accounts.online_players",
        title: "Online Players",
        type: "item",
        translate: "Online_Players",
        icon: "feather:users",
        url: "/accounts/online_players",
      },
      {
        id: "accounts.affiliates",
        title: "Affiliate",
        type: "item",
        translate: "Affiliate",
        icon: "feather:users",
        url: "/accounts/affiliates",
      },
    ],
  },
  {
    id: "accesslogs",
    title: "Access logs",
    subtitle: "Access logs",
    type: "item",
    icon: "feather:server",
    translate: "Access_logs",
    url: "/accesslogs",
    children: [],
    auth: authRoles.access_logs,
  },
  {
    id: "settings",
    title: "Settings",
    subtitle: "Settings",
    type: "group",
    icon: "feather:settings",
    translate: "Settings",
    auth: authRoles.setting,
    children: [
      {
        id: "settings.market_dictionary",
        title: "Market Dictionary",
        type: "item",
        translate: "Market_Dictionary",
        auth: authRoles.admin,
        icon: "feather:book-open",
        url: "/settings/market_dictionary",
      },
      {
        id: "settings.dictionary",
        title: "Dictionary",
        type: "item",
        translate: "Theme_Dictionary",
        auth: authRoles.admin,
        icon: "feather:book-open",
        url: "/settings/dictionary",
      },
      {
        id: "settings.casino_vendors",
        title: "Casino Vendors",
        translate: "Casino_Vendors",
        type: "item",
        auth: authRoles.admin,
        icon: "material-solid:casino",
        url: "/casino/vendors_and_providers",
      },
      /*{
        id: "settings.games",
        title: "Casino Games",
        translate: "Casino_Games",
        type: "item",
        auth: authRoles.admin,
        icon: "feather:globe",
        url: "/settings/casino/games",
      },*/
      {
        id: "settings.online_system",
        title: "Online System",
        translate: "Online_System",
        type: "item",
        auth: authRoles.online,
        icon: "feather:globe",
        url: "/settings/online_system/sm/online_system_settings",
      },
      {
        id: "settings.betshop_system",
        title: "Betshop System",
        translate: "Betshop_System",
        type: "item",
        auth: authRoles.betshop,
        icon: "material-solid:desktop_mac",
        url: "/settings/betshop_system/sm/betshop_system_settings",
      },
      {
        id: "settings.settings",
        title: "Miscs. Settings",
        translate: "Miscs_Settings",
        type: "item",
        auth: authRoles.betshop_admin,
        icon: "feather:settings",
        url: "/betshop_admin/settings",
      },
      // {
      //   id: 'settings.betshop',
      //   title: 'Bet Shop',
      //   translate: 'BET_SHOP',
      //   type: 'item',
      //   icon: 'feather:globe',
      //   url: '/settings/betshop/sm/detail',
      // },
    ],
  },
  //////////////////stuff permissions
  {
    id: "stuff_sportData",
    title: "Sport data",
    subtitle: "Sport Data",
    type: "group",
    icon: "material-solid:sports_handball",
    auth: authRoles.staff,
    customPermission:
      "sport_events_data_page_view,sport_event_status_change,betting_setting_bet_market_status_change,betting_setting_bet_market_view",
    customPermissionRoles: permissionRoles.staff,
    translate: "Sport_Data",
    children: [
      {
        id: "dashboards.sport-event",
        title: "Sport Events",
        translate: "Sport_Events",
        type: "item",
        icon: "material-outline:emoji_events",
        url: "/sport-data/events",
        auth: authRoles.staff,
        customPermission: "sport_events_data_page_view,sport_event_status_change",
        customPermissionRoles: permissionRoles.staff,
      },
      {
        id: "dashboards.betting-settings",
        title: "Betting Setting/Bet Markets",
        translate: "Betting_Setting",
        type: "item",
        icon: "feather:settings",
        url: "/sport-data/betting_setting",
        auth: authRoles.staff,
        customPermission: "betting_setting_bet_market_status_change,betting_setting_bet_market_view",
        customPermissionRoles: permissionRoles.staff,
      },
    ],
  },
  {
    id: "stuff_management.system",
    title: "Systems/Operators",
    subtitle: "Unique Systems/Operators",
    type: "item",
    icon: "material-solid:desktop_mac",
    translate: "Systems_Operators",
    url: "/management/systems",
    children: [],
    auth: authRoles.staff,
    customPermission: "create_any_type_system,create_change_system_status",
    customPermissionRoles: permissionRoles.staff,
  },
  {
    id: "staff_finance",
    title: "Finance",
    subtitle: "Unique Finance",
    type: "group",
    icon: "feather:dollar-sign",
    auth: authRoles.staff,
    customPermission: "add_credits_balance_for_systems,financial_report_page_view,transactions_log",
    customPermissionRoles: permissionRoles.staff,
    translate: "Finance",
    //url: '/management/finance',
    children: [
      {
        id: "finance.system",
        title: "System",
        translate: "System",
        type: "item",
        icon: "material-solid:desktop_mac",
        auth: authRoles.staff,
        customPermission: "add_credits_balance_for_systems",
        customPermissionRoles: permissionRoles.staff,
        url: "/finance/system",
      },
      {
        id: "finance.system_financial",
        title: "System Financial",
        translate: "System_Financial",
        type: "item",
        icon: "feather:dollar-sign",
        auth: authRoles.staff,
        customPermission: "financial_report_page_view",
        customPermissionRoles: permissionRoles.staff,
        url: "/finance/system_financial",
      },
      {
        id: "finance.transactions_logs",
        title: "Transactions Logs",
        translate: "Transactions_Logs",
        type: "item",
        icon: "feather:dollar-sign",
        auth: authRoles.staff,
        customPermission: "transactions_log",
        customPermissionRoles: permissionRoles.staff,
        url: "/finance/transactions_logs",
      },
    ],
  },
  {
    id: "staff_settings",
    title: "Settings",
    subtitle: "Settings",
    type: "group",
    icon: "feather:users",
    translate: "Settings",
    auth: authRoles.staff,
    customPermission: "translation_and_order_pages_view_and_edit,casino_vendor_change_status,casino_vendor_page_view",
    customPermissionRoles: permissionRoles.staff,
    children: [
      {
        id: "settings.market_dictionary",
        title: "Market Dictionary",
        type: "item",
        translate: "Market_Dictionary",
        auth: authRoles.staff,
        icon: "feather:book-open",
        url: "/settings/market_dictionary",
        customPermission: "translation_and_order_pages_view_and_edit",
        customPermissionRoles: permissionRoles.staff,
      },
      {
        id: "settings.dictionary",
        title: "Dictionary",
        type: "item",
        translate: "Theme_Dictionary",
        auth: authRoles.staff,
        customPermission: "translation_and_order_pages_view_and_edit",
        customPermissionRoles: permissionRoles.staff,
        icon: "feather:book-open",
        url: "/settings/dictionary",
      },
      {
        id: "casino.vendors",
        title: "Casino Vendors",
        translate: "Casino_Vendors",
        type: "item",
        auth: authRoles.staff,
        customPermission: "casino_vendor_change_status,casino_vendor_page_view",
        customPermissionRoles: permissionRoles.staff,
        icon: "material-solid:casino",
        url: "/casino/vendors_and_providers",
      },
      /*{
        id: "casino.games",
        title: "Casino Games",
        translate: "Casino_Games",
        type: "item",
        auth: authRoles.staff,
        customPermission: "casino_vendor_change_status,casino_vendor_page_view",
        customPermissionRoles: permissionRoles.staff,
        icon: "feather:globe",
        url: "/casino/games",
      },*/
    ],
  },
];

export default navigationConfig;

import { lazy } from 'react';
import authRoles from "../../auth/authRoles";

const CasinoVendorsAndProviders = lazy(() => import('./CasinoVendorsAndProviders'));

const CasinoVendorsAndProvidersConfigs = [{
  settings: {
    layout: {},
  },
 auth: authRoles.admin,
  routes: [
    {
      path: 'casino/vendors_and_providers',
      element: <CasinoVendorsAndProviders />,
    },
  ],
}];

export default CasinoVendorsAndProvidersConfigs;
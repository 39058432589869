import { lazy } from 'react';
import authRoles from "../../auth/authRoles";

// const Affiliates = lazy(() => import('./affiliates/index'));
const Dashboard = lazy(() => import('./Dashboard/Dashboard'));
const Settings = lazy(() => import('./Settings/Settings'));
const FinancialReports = lazy(() => import('./Reports/Financial/Financial'));
const SportBetSlipReports = lazy(() => import('./Reports/SportBetSlipReports/SportBetSlipReports'));
const CasinoReports = lazy(() => import('./Reports/CasinoReports/CasinoReports'));
const FiananceManagement = lazy(() => import('./FinanceManagement/FinanceManagement'));
const CouponManagement = lazy(() => import('./CouponManagement/CouponManagement'));

const betshop_adminConfigs = [{
  settings: {
    layout: {},
  },
  auth: authRoles.dashboard,
  routes: [
    {
      path: 'betshop_admin/dashboard',
      element: <Dashboard />,
    },
    {
        path: 'betshop_admin/settings',
        element: <Settings />,
      },
      {
        path: 'betshop_admin/financial_reports',
        element: <FinancialReports />,
      },
      {
        path: 'betshop_admin/sport_bet_slip_reports',
        element: <SportBetSlipReports />,
      },
      {
        path: 'betshop_admin/casino_reports',
        element: <CasinoReports />,
      },
      {
        path: 'betshop_admin/fianance_management',
        element: <FiananceManagement />,
      },
      {
        path: 'betshop_admin/coupon_management',
        element: <CouponManagement />,
      },
  ],
}];

export default betshop_adminConfigs;
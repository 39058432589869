import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { authRoles } from "src/app/auth";
import jwtServiceConfig from "src/app/auth/services/jwtService/jwtServiceConfig";

const api_domain = jwtServiceConfig.apiUrl;

export const getVendors = createAsyncThunk("betshopAdmin/getVendors", async () => {
  const { data } = await axios.get(`${api_domain}vendors/getAll`);
  return data.vendors || [];
})

export const getCasinoReports = createAsyncThunk(
  "betshopAdmin/getCasinoReports",
  async ({
    user,
    filter
  }) => {
    const { startDate, endDate, admin, supplier, user: username, vendor, gameType: game } = filter;
    if (authRoles.online.includes(user.role)) {
      let res = [];
      const gameType = game === "all" ? "all" : game;
      const { data } = await axios.get(`${api_domain}online/report/casino/${startDate}/${endDate}/${username}/${vendor}/${game}`);
      return data.casino_report || [];
    } else {
      // betshop
      try {
        let res = [];
        const gameType = game === "all" ? "all" : game;
  
        if (admin === "all") {
          res = await axios.get(`${api_domain}betshop/report/casino/${startDate}/${endDate}/${vendor}/${gameType}`);
        } else if (supplier === "all") {
          res = await axios.get(`${api_domain}betshop/report/casino/${startDate}/${endDate}/admin/${admin}/${vendor}/${gameType}`);
        } else if (username === "") {
          res = await axios.get(`${api_domain}betshop/report/casino/${startDate}/${endDate}/supplier/${supplier}/${vendor}/${gameType}`);
        } else {
          res = await axios.get(`${api_domain}betshop/report/casino/${startDate}/${endDate}/user/${username}/${vendor}/${gameType}`);
        }
        return res.data.casino_report || [];
      } catch (_) {
        // 
        return [];
      }
    }
  }
)

const reportAdapter = createEntityAdapter();

export const { selectAll: selectCasinoReports } = reportAdapter.getSelectors(state => state.betshopAdmin.casinoReports);

const reportSlice = createSlice({
  name: "betshopAdmin/casinoReports",
  initialState: reportAdapter.getInitialState(),
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getCasinoReports.fulfilled, (state, action) => {
      reportAdapter.setAll(state, action.payload);
    })
  }
})

export default reportSlice.reducer;

import { combineReducers } from "@reduxjs/toolkit";
import system from "./systemSlice";
import systemFinancials from "./systemFinancialsSlice";
import systemFinancial from "./systemFinancialSlice";
import transactionsLogs from "./transactionsLogsSlice";
import transactionLogs from "./transactionLogsSlice";
import financialReport from "./financialReportSlice";
import balance from "./balanceSlice";

const reducer = combineReducers({
    system,
    systemFinancials,
    systemFinancial,
    transactionsLogs,
    transactionLogs,
    financialReport,
    balance,
})

export default reducer;
import themesConfig from "app/configs/themesConfig";
import i18n from "../../i18n";
import authRoles from "../auth/authRoles";

const settingsConfig = {
  layout: {
    style: "layout2", // layout1 layout2 layout3
    config: {}, // checkout default layout configs at app/theme-layouts for example  app/theme-layouts/layout1/Layout1Config.js
  },
  customScrollbars: true,
  direction: i18n.dir(i18n.options.lng) || "ltr", // rtl, ltr
  theme: {
    main: themesConfig.default,
    navbar: themesConfig.defaultDark,
    toolbar: themesConfig.default,
    footer: themesConfig.defaultDark,
  },

  /*
   To make whole app auth protected by default set defaultAuth:['admin','staff','user']
   To make whole app accessible without authorization by default set defaultAuth: null
   *** The individual route configs which has auth option won't be overridden.
   */

  // defaultAuth: ['admin', 'affiliate', 'affiliate_owner'],
  defaultAuth: authRoles.user,
  betTypes: [
    {
      id: "Live",
      name: "Live",
    },
    {
      id: "unplayed",
      name: "PreMatch",
    },
  ],
  dictionaryGroup: [
    {
      id: "admin",
      name: "Admin_Translates",
    },
    {
      id: "website",
      name: "Website_Translates",
    },
    {
      id: "sport",
      name: "Sport_Translates",
    },
    {
      id: "league",
      name: "League_Translates",
    },
    {
      id: "country",
      name: "Country_Translates",
    },
    {
      id: "team",
      name: "Team_Name_Translates",
    },
    {
      id: "collection",
      name: "Collection_Translates",
    },
    {
      id: "marketGroup",
      name: "Market_Group_Translates",
    },
  ],
  roles: [
    {
      id: "manager",
      name: "Manager",
    },
    {
      id: "staff",
      name: "Staff",
    },
  ],
  departmentUserRoles: [
    {
      id: "staff",
      name: "Staff",
    },
  ],
  systemTypes: [
    {
      value: 1,
      label: "Online",
    },
    {
      value: 2,
      label: "Betshop",
    },
  ],
  allStatuses: [
    {
      value: "all",
      label: "All",
    },
    {
      value: "open",
      label: "Waiting",
    },
    {
      value: "won",
      label: "Won",
    },
    {
      value: "lost",
      label: "Lost",
    },
    {
      value: "cash-out",
      label: "Cash Out",
    },
    {
      value: "cancel",
      label: "Cancel",
    },
    {
      value: "refunded",
      label: "Refunded",
    },
  ],
  allGameType: [
    {
      value: "all",
      label: "All",
    },
    {
      value: "live",
      label: "Live",
    },
    {
      value: "prematch",
      label: "PreMatch",
    },
    // {
    //   value: 'combo',
    //   label: 'Combo'
    // }
  ],
  allCouponType: [
    {
      value: "all",
      label: "All",
    },
    {
      value: "single",
      label: "Single",
    },
    {
      value: "system",
      label: "System",
    },
    {
      value: "combo",
      label: "Combo",
    },
  ],
  currency: [
    {
      value: "all",
      label: "All",
    },
    {
      value: "try",
      label: "TRY",
    },
    {
      value: "usd",
      label: "USD",
    },
  ],

  currencies: [
    {
      id: "TRY",
      name: "TRY",
    },
    {
      id: "USD",
      name: "USD",
    },
    {
      id: "EUR",
      name: "EUR",
    },
    {
      id: "USDT",
      name: "USDT",
    },
  ],
  amountStatus: [
    {
      value: "Deposit",
      label: "Deposit",
    },
    {
      value: "Withdraw",
      label: "Withdraw",
    },
  ],
  currencyType: [
    {
      value: "try",
      label: "TRY",
    },
    {
      value: "usd",
      label: "USD",
    },
    {
      value: "eur",
      label: "EUR",
    },
    {
      value: "usdt",
      label: "USDT",
    },
  ],
  systemType: [
    {
      value: "Doxwin",
    },
    {
      value: "Nexbet",
    },
  ],
  games: [
    {
      value: "All",
      label: "All",
    },
    {
      value: "Sports",
      label: "Sports",
    },
    {
      value: "Live Casino",
      label: "Live Casino",
    },
    {
      value: "Slots",
      label: "Slots",
    },
    {
      value: "TableGames",
      label: "TableGames",
    },
    {
      value: "Poker",
      label: "Poker",
    },
  ],
  transactions: [
    {
      value: "all",
      label: "All",
    },
    {
      value: "try",
      label: "TRY",
    },
    {
      value: "usd",
      label: "USD",
    },
  ],
  clientTypes: [
    {
      value: "vip",
      label: "VIP",
    },
  ],
  modules: [
    {
      value: "sports",
      label: "Sports",
    },
    {
      value: "casino",
      label: "Casino",
    },
    {
      value: "payment",
      label: "Payment",
    },
  ],
  cmsPromotionSectionTypes: [
    {
      value: "popular",
      label: "Popular",
    },
    {
      value: "welcome",
      label: "Welcome",
    },
    {
      value: "sports",
      label: "Sports",
    },
    {
      value: "casino",
      label: "Casino",
    },
    {
      value: "live_casino",
      label: "Live Casino",
    },
    {
      value: "games",
      label: "Games",
    },
    {
      value: "poker",
      label: "Poker",
    },
    {
      value: "others",
      label: "Others",
    },
  ],
  cmsLandingpageSectionTypes: [
    {
      value: "main_banner",
      label: "Main Banner",
    },
    {
      value: "game_modules",
      label: "Game Modules",
    },
  ],
  cmsBannerSectionTypes: [
    {
      value: "sports",
      label: "Sports",
    },
    {
      value: "casino",
      label: "Casino",
    },
    {
      value: "live_casino",
      label: "Live Casino",
    },
    {
      value: "games",
      label: "Games",
    },
    {
      value: "poker",
      label: "Poker",
    },
    {
      value: "virtuals",
      label: "Virtuals",
    },
    {
      value: "others",
      label: "Others",
    },
  ],
  cmsPageOfContentsSectionTypes: [
    {
      value: "company",
      label: "Company",
    },
    {
      value: "terms_and_privacy",
      label: "Terms and Privacy",
    },
    {
      value: "rules",
      label: "Rules",
    },
    {
      value: "responsible_gambling",
      label: "Responsible Gambling",
    },
    {
      value: "faq",
      label: "FAQ",
    },
  ],
  paymentGateways: [
    { value: "Maxipara", label: "Maxipara" },
    { value: "CoinPayment", label: "Coinpayment" },
  ],
  /*
    Default redirect url for the logged-in user,
   */
  loginRedirectUrl: "/",
};

export default settingsConfig;

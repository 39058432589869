import { combineReducers } from '@reduxjs/toolkit'
import betslipReports from "./betslipReportSlice";
import casinoReports from './casinoReportSlice';

const reducer = combineReducers({
	betslipReports,
	casinoReports
})

export default reducer

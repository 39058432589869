import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import authRoles from "../../auth/authRoles";

const MarketDictionary = lazy(() => import('./market_dictionary/MarketDictionary'));
const Dictionary = lazy(() => import('./dictionary/Dictionary'));
const OnlineSystemMenu = lazy(() => import('./online_system/OnlineSystemMenu'));
const GeneralOperatorSettings = lazy(() => import('./online_system/general_operator_settings/GeneralOperatorSettings'));
const BetshopSystemMenu = lazy(() => import('./betshop_system/BetshopSystemMenu'));
const BetshopGeneralOperatorSettings = lazy(() => import('./betshop_system/general_operator_settings/GeneralOperatorSettings'));
// const BetShopMenu = lazy(() => import('./betshop/BetShopMenu'));
// const BetShopMiscSettings = lazy(() => import('./betshop/misc_settings/MiscSettings'));

const settingsConfigs = [{
  settings: {
    layout: {},
  },
  // auth: authRoles.manager_staff,
  routes: [
    {
      path: 'settings/market_dictionary',
      element: <MarketDictionary />,
    },
    {
      path: 'settings/dictionary',
      element: <Dictionary />,
    },
    {
      path: 'settings/online_system/sm/',
      element: <OnlineSystemMenu />,
      children: [
        {
          path: 'online_system_settings',
          element: <GeneralOperatorSettings />
        }
      ]
    },
    {
      path: 'settings/betshop_system/sm/',
      element: <BetshopSystemMenu />,
      children: [
        {
          path: 'betshop_system_settings',
          element: <BetshopGeneralOperatorSettings />
        }
      ]
    },
    // {
    //   path: 'settings/betshop/sm/',
    //   element: <BetShopMenu />,
    //   children: [
    //     {
    //       path: 'detail',
    //       element: <BetShopMiscSettings />
    //     }
    //   ]
    // },
    // {
    //   path: 'settings/misc_settings',
    //   element: <MiscSettings />,
    // },
    // {
    //   path: 'settings/sport_settings',
    //   element: <SportSettins />,
    // },
    // {
    //   path: 'settings/bet_settings',
    //   element: <BetSettings />,
    // },
    // {
    //   path: 'settings/global_betting_settings',
    //   element: <GlobalBettingSettings />,
    // },
  ],
},
];

export default settingsConfigs;
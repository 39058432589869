import { lazy } from "react";
import { Navigate } from "react-router-dom";

const Dashboard = lazy(() => import("./Dashboard"));

const dashboardsConfigs = [
  {
    settings: {
      layout: {},
    },
    routes: [{ path: "/", element: <Navigate to="/dashboards" /> }],
  },
  {
    settings: {
      layout: {},
    },
    routes: [
      {
        path: "dashboards",
        element: <Dashboard />,
      },
    ],
  },
];

export default dashboardsConfigs;

import {
    createAsyncThunk,
    createEntityAdapter,
    createSlice,
} from "@reduxjs/toolkit";
import axios from "axios";
import jwtServiceConfig from "src/app/auth/services/jwtService/jwtServiceConfig";

const api_domain = jwtServiceConfig.apiUrl;

export const getFilteredTransactionLogs = createAsyncThunk(
  "financeApp/transactionLogs/getFilteredTransactionLogs",
  async (body) => {
    let url = "";
    if (body.user.role == "betshop_operator") {
      url = `${api_domain}betshop/finance/transactions/${body.admin}/${body.startDate}/${body.endDate}`;
    }
    if (body.user.role == "betshop_admin") {
      url = `${api_domain}betshop_admin/finance/transactions/${body.user.uuid}/${body.startDate}/${body.endDate}`;
    }
    const response = await axios.get(url);
    const data = await response.data.transactions;

    return data === undefined ? null : data;
  }
);

export const getAllTransactionLogs = createAsyncThunk(
  "financeApp/transactionLogs/getAllTransactionLogs",
  async ({ user, filter }) => {
    const { startDate, endDate, user: username, module, currency, type } = filter;
    let url = "";
    if (user.role == "betshop_operator") {
      url = `${api_domain}betshop/finance/transactions`;
    }else if (user.role == "betshop_admin") {
      url = `${api_domain}betshop_admin/finance/transactions/${user.uuid}`;
    } else if (user.role == "betshop_supplier") {
      url = `${api_domain}betshop_supplier/finance/transactions/${user.uuid}`;
    } else if (user.role === "online_operator") {
      url = `${api_domain}online/finance/transactions/${startDate}/${endDate}/${username}/game/${currency}/${type}`
    }
    const response = await axios.get(url);
    const data = await response.data.transactions;

    return data === undefined ? null : data;
  }
);

const transactionLogsAdapter = createEntityAdapter({});

export const { selectAll: selectAllTransactionLogs } =
  transactionLogsAdapter.getSelectors(
    (state) => state.financeApp.transactionLogs
  );

const transactionLogsSlice = createSlice({
  name: "financeApp/transactionLogs",
  initialState: transactionLogsAdapter.getInitialState({}),
  reducers: {},
  extraReducers: {
    [getAllTransactionLogs.fulfilled]: (state, action) => {
      if (!action.payload) {
        transactionLogsAdapter.setAll(state, {});
      } else {
        transactionLogsAdapter.setAll(state, action.payload);
      }
    },
    [getFilteredTransactionLogs.fulfilled]: (state, action) => {
      if (!action.payload) {
        transactionLogsAdapter.setAll(state, {});
      } else {
        transactionLogsAdapter.setAll(state, action.payload);
      }
    },
  },
});

export default transactionLogsSlice.reducer;
